@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.cms-module {
  &-highlighted-products {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;
    position: relative;
    overflow: hidden;
    height: 100vh;

    .bg-image {
      position: absolute;
      z-index: 0;
      height: 50%;
      object-fit: cover;
      width: 100%;
      top: 0;
      left: 0;
    }

    &:after {
      z-index: 1;
      background-color: black;
      content: '';
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 50%;
      background-size: cover;
    }

    .ant-col {
      z-index: 2;
    }

    &-title {
      text-align: center;
      * {
        color: white !important;
      }
    }

    @media @tablet-down {
      padding-left: 20px;
      padding-right: 20px;
    }

    .highlighted-products-link {
      text-align: center;
      margin-top: 50px;
      button {
        min-width: 150px;
      }
    }

    .highlighted-products-navigation {
      position: absolute;
      right: -70px;
      top: calc(50% - 50px);

      @media @desktop-down {
        right: -50px;
      }
      @media @tablet-down {
        left: 0;
        width: 100%;
        top: unset;
      }

      &-arrow {
        font-size: 20px;
        display: block;
        margin-bottom: 20px;
        width: 40px;

        svg {
          line,
          polygon {
            fill: none;
            transition: stroke 0.2s ease-in-out;
          }
        }

        @media @tablet-down {
          &.arrow-left {
            position: absolute;
            left: 0px;
          }
          &.arrow-right {
            position: absolute;
            right: 0px;
          }
        }

        &:hover {
          cursor: pointer;
          svg {
            line,
            polygon {
              transition: stroke 0.2s ease-in-out;
              stroke: @theme-color-red;
            }
          }
        }
      }
    }

    .slick-slide {
      padding-left: 10px;
      padding-right: 10px;
      transition: opacity 0.2s ease-in-out;
      @media @tablet-down {
        padding-left: 0px;
        padding-right: 0px;
        opacity: 0.3;
        width: 70vw;
        //margin-right: 30px;
        &.slick-active {
          opacity: 1;
          transition: opacity 0.2s ease-in-out;
        }
      }
      .product-slide {
        padding: 2px;
      }

      .highlighted-product {
        height: 400px;
        text-align: center;
        position: relative;
        overflow: hidden;

        &-title {
          color: black;

          span {
            font-size: 1rem;
          }

          h3 {
            font-size: 2rem;
            line-height: 1.5rem;
            text-transform: capitalize;
            font-weight: 700;
          }

          position: relative;
          margin-top: 20px;
          left: unset;
          text-align: center;

          h3 {
            font-size: 1rem;
            font-weight: 500;
            text-transform: uppercase;
          }
        }

        img {
          left: 0;
          transition: transform 0.3s ease-out;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        &:hover {
          img {
            transform: scale(1.1);
            transition: transform 0.3s ease-in;
          }
        }
      }
    }
  }
}
