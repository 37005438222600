@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';

.page-wedding {
  #section-variant-a {
    overflow: hidden;


    .ant-col:nth-child(2) {
      &:before {
        content: ' ';
        background-image: url('../../assets/bride.jpg');
        @media @large-desktop-down {
          display: none;
        }
      }
    }

    &:after {
      content: ' ';
      background-image: url('../../assets/wedding-white.jpeg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 300px;
      height: 500px;
      z-index: 33;
      position: absolute;
      display: block;
      bottom: -20px;
      right: 100px;

      @media @extra-large-desktop-down {
        display: none;
      }
    }
  }

  #section-variant-f .ant-col:nth-child(1) p:first-child {
    width: 100%;
  }
}