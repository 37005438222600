@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.cms-module-slider-partners {
  padding-top: @theme-section-padding;
  padding-bottom: @theme-section-padding;

  @media @tablet-down {
    padding-top: @theme-section-padding-mobile;
    padding-bottom: @theme-section-padding-mobile;
  }

  .slider-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .slider-navigation {
      display: inline-block;
      height: 20px;
      width: 50px;
      margin-left: 10px;
      margin-right: 10px;
    }
    .ant-carousel {
      //display: inline-block;
      min-height: 200px;
      min-width: 100px;
      max-width: calc(100% - 120px);
    }
  }

  position: relative;
  z-index: 3;
  h2 {
    text-align: center;
  }

  .brand-slider-row {
    margin-top: 50px;
    .slick-slider {
      height: auto;
      img {
        margin: 0 auto;
        height: 70px;
        @media @desktop-down {
          height: 50px;
        }
        @media @tablet-down {
          height: 70px;
        }
        @media @tablet-down {
          height: 80px;
        }
      }
    }
  }
}
