@import (once) '../breakpoints.less';

.cms-module {
  &-two-colored-blocks {
    padding-top: 80px;
    padding-bottom: 80px;

    p {
      font-size: 1.2rem;
      line-height: 2rem;
    }

    @media @tablet-down {
      hr {
        margin-top: 40px;
      }
      padding-bottom: 20px;
      padding-top: 20px;
    }

    hr {
      margin-bottom: 80px;
    }

    .colored-block {
      height: 350px;
      margin-bottom: 30px;
      overflow: hidden;

      &-first {
        margin-right: 15px;
      }
      &-second {
        margin-left: 15px;
      }

      @media @large-desktop-down {
        height: auto;
      }

      &-description {
        padding: 20px;
        padding-top: 40px;

        h3 {
          color: white;
          font-size: 1.5rem;
          line-height: 1.5rem;
          padding-right: 10px;
          margin-bottom: 30px;
        }

        p {
          color: white;
          margin-bottom: 20px;
        }

        button {
          padding-left: 15px;
          padding-right: 15px;
          position: absolute;
          bottom: 20px;
          @media @large-desktop-down {
            position: relative;
            bottom: unset;
          }
          @media @tablet-down {
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }

      img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        object-position: center center;
        @media @large-desktop-down {
          height: 200px;
        }
      }
    }
  }
}
