@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.cms-module {
  &-column-layout {
    position: relative;
    background-size: cover;
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;

    overflow-x: hidden;

    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    &-title {
      width: 100%;
      text-align: center;
    }
    &-subtitle {
      font-size: 1.2rem;
      text-align: center;
    }

    &.spacing {
      &-small {
        .ant-col {
          padding-left: 30px;
          padding-right: 30px;
          @media @tablet-down {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
      &-medium {
        .ant-col {
          padding-left: 30px;
          padding-right: 30px;

          @media @tablet-down {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
      &-large {
        min-height: 700px;

        .ant-col {
          padding-left: 30px;
          padding-right: 30px;

          @media @tablet-down {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }

    &.has-bg-image {
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.4;
      }

      .ant-col {
        h2,
        h3,
        p {
          color: white;
        }
      }
    }

    .ant-col {
      background-size: cover;

      a {
        color: @theme-color !important;
        &:hover {
          color: @theme-color-highlight !important;
        }
        display: inline-block;

        button {
          margin-top: 40px;
          //margin-bottom: 40px;
          @media @phone-down {
            display: block !important;
            width: 100%;
          }
        }
      }

      img {
        //max-height: 500px;
        max-width: 100%;
        object-fit: cover;
        margin-bottom: 40px;
        width: 100%;
      }

      ul {
        margin-bottom: 0;
        padding-left: 15px;
        li {
          font-size: 1.2rem;
        }
      }

      h3 {
        //font-size: 2rem;
        //line-height: 2rem;
        //margin-bottom: 0;
        color: @theme-color;

        @media @tablet-down {
          //font-size: 1.3rem;
          //line-height: 1.3rem;
        }
      }
      p {
        margin-bottom: 0;
        color: @theme-color-black;
        span {
          color: @theme-color-black;
        }
      }
    }

    &-button-row {
      text-align: center;
    }

    .fluid-decoration-element {
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 2;

      &.bottom {
        bottom: 0px;
      }
      &.top {
        top: 0px;
      }
    }
  }
}
