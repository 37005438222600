@import (once) '../breakpoints.less';

.cms-module {
  &-image-wall {
    .image-block {
      height: 450px;
      text-align: center;
      position: relative;
      overflow: hidden;
      &-title {
        position: absolute;
        width: 100%;
        left: 0;
        padding-left: 20px;
        padding-right: 20px;

        &.alignment-left {
          text-align: left;
        }
        &.alignment-right {
          text-align: right;
        }
        &.alignment-center {
          text-align: center;
        }
        &.position-top {
          top: 40px;
        }
        &.position-middle {
          top: 50%;
        }
        &.position-bottom {
          bottom: 40px;
        }

        color: black;
        span {
          font-size: 1rem;
        }
        h3 {
          font-size: 2rem;
          line-height: 1.5rem;
          text-transform: capitalize;
          font-weight: 700;
        }
      }

      img {
        left: 0;
        transition: transform 0.3s ease-out;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:hover {
        img {
          transform: scale(1.1);
          transition: transform 0.3s ease-in;
        }
      }
    }
  }
}
