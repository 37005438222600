@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.cms-module {
  &-waiting-list-signup {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;

    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    h2 {
      text-align: center;
    }

    #cms-module-waiting-list-signup-description {
      text-align: center;
    }

    @media @tablet-down {
      padding-left: 20px;
      padding-right: 20px;
    }

    #cms-module-waiting-list-signup-button {
      text-align: center;
    }
  }
}
