@import (once) '../breakpoints.less';

.cms-module {
  &-photo-gallery {
    padding-top: 80px;
    padding-bottom: 80px;

    @media @tablet-down {
      padding-top: 20px;
    }

    .ant-carousel {
      height: auto;
      width: 100%;
    }

    img {
      object-fit: cover;
    }
  }
}

.page-home {
  .cms-module {
    &-photo-gallery {
      img {
        max-height: 200px;
      }
    }
  }
}
