.ant-skeleton.skeleton-image {
  .ant-skeleton-content {
    ul.ant-skeleton-paragraph {
      li {
        height: 250px !important;
        width: 100% !important;
      }
    }
  }
}

.ant-skeleton.skeleton-image-small {
  .ant-skeleton-content {
    ul.ant-skeleton-paragraph {
      li {
        height: 75px !important;
        width: 100% !important;
      }
    }
  }
}
