@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';

.page-events {
  #section-default {
    padding-top: 50px;
    padding-bottom: 50px;

    .ant-col {
      background-color: rgba(44, 47, 46,0.9);
    }
  }

  #section-variant-a {
    .ant-col:nth-child(2) {
      &:before {
        top: -280px;
        background-image: url('../../assets/new-york.jpeg');
      }
    }
  }

  #section-variant-g {
    .ql-align-center {
      margin-bottom: 0;
    }
    h2 {
      margin-bottom: 20px !important;
    }
    .slider-slide {
      padding: 5px;
    }

    .ant-carousel {
      margin-top: 40px;
      margin-bottom: 0;
    }

    .ant-row {
      width: 100%;
    }

    .button-row {
      text-align: center;
    }
  }

  #section-variant-f {

    @media @desktop-down {
      .ant-col {
        flex: 0 0 85%;
        max-width: 85%;
      }
    }

   .ant-col:nth-child(1) p img {
     height: 400px;
   }

    @media @tablet-down {
      .ant-col {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .ant-col:nth-child(1) p:nth-child(2) {
      img {
        height: 20px;
        width: auto;
      }
    }
    p {
      width: 100%;
      margin-top: 0 !important;
    }
  }
}

#section-variant-i {

  h2 {
    color: #333;
  }

  img {
    width: auto;
    height: 20px;
  }

  //-khtml-opacity:.30;
  //-moz-opacity:.30;
  //-ms-filter:"alpha(opacity=50)";
  //filter:alpha(opacity=30);
  //filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.3);
  //opacity:.30;

}