* {
  font-family: 'Lora', sans-serif;
  font-variant: none;
}
.ant-btn {
  background-color: #bd9557;
  border-color: #bd9557;
  border-radius: 10px;
}
.ant-btn:hover {
  background-color: #c3b191;
  border-color: #c3b191;
}
footer #copyright img {
  background-color: #333;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}
@media (max-width: 991px) {
  footer .footer-link {
    padding-left: 0 !important;
  }
}
h2 {
  font-size: 2rem;
  color: #bd9557;
}
h3 {
  color: #333 !important;
}
#section-promo {
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
}
#section-promo:before {
  background-image: url('../../assets/wave-vertical.png');
  background-repeat: no-repeat;
  opacity: 1;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1200px;
  background-size: contain;
  background-position: -150px;
}
@media (max-width: 1600px) {
  #section-promo:before {
    left: -120px;
  }
}
@media (max-width: 1199px) {
  #section-promo:before {
    left: -200px;
  }
}
@media (max-width: 991px) {
  #section-promo:before {
    opacity: 0.6;
  }
}
@media (max-width: 768px) {
  #section-promo:before {
    background-size: cover;
    background-position: 0;
  }
}
#section-promo h1 {
  width: 330px;
  color: #333;
  font-size: 3rem;
  line-height: 3.5rem;
  margin-bottom: 50px;
}
@media (max-width: 1199px) {
  #section-promo h1 {
    width: 100%;
  }
}
#section-promo img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  #section-promo img {
    margin-bottom: 20px;
  }
}
#section-promo p {
  display: block;
  width: 500px;
  color: #333;
}
@media (max-width: 1199px) {
  #section-promo p {
    width: 400px;
  }
}
@media (max-width: 768px) {
  #section-promo p {
    width: 100%;
  }
}
#section-variant-a {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 768px) {
  #section-variant-a {
    background-size: auto 120%;
  }
}
#section-variant-a .ant-col:nth-child(1) {
  padding-left: 200px;
}
@media (max-width: 991px) {
  #section-variant-a .ant-col:nth-child(1) {
    padding-left: 50px;
  }
}
#section-variant-a .ant-col:nth-child(1):before {
  content: ' ';
  background-image: url('../../assets/pattern-a.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 500px;
  height: 500px;
  z-index: 33;
  position: absolute;
  display: block;
  top: 150px;
  left: -150px;
  opacity: 0.5;
}
@media (max-width: 991px) {
  #section-variant-a .ant-col:nth-child(1):before {
    top: 50px;
  }
}
#section-variant-a .ant-col:nth-child(1) p:first-child img {
  width: 400px;
}
@media (max-width: 768px) {
  #section-variant-a .ant-col:nth-child(1) p:first-child img {
    width: 80%;
    float: right;
  }
}
@media (max-width: 991px) {
  #section-variant-a .ant-col:nth-child(1) p:nth-child(2) {
    display: none;
  }
}
#section-variant-a .ant-col:nth-child(2):before {
  content: ' ';
  background-image: url('../../assets/wedding-white.jpeg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 500px;
  z-index: 33;
  position: absolute;
  display: block;
  top: -250px;
  right: 100px;
}
@media (max-width: 1600px) {
  #section-variant-a .ant-col:nth-child(2):before {
    right: 20px;
    width: 270px;
    top: -280px;
  }
}
@media (max-width: 1199px) {
  #section-variant-a .ant-col:nth-child(2):before {
    content: none;
  }
}
@media (max-width: 991px) {
  #section-variant-a .ant-col:nth-child(2):before {
    content: none;
  }
}
#section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2 {
  width: 350px;
}
#section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2:after {
  content: ' ';
  background-image: url('../../assets/pattern-a.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 500px;
  height: 500px;
  z-index: 33;
  position: absolute;
  display: block;
  top: 100px;
  right: 0px;
  opacity: 0.3;
}
@media (max-width: 1600px) {
  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2:after {
    top: 0px;
    width: 450px;
    right: -100px;
  }
}
@media (max-width: 1199px) {
  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2:after {
    content: none;
  }
}
#section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content img {
    margin-bottom: 20px;
  }
}
#section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content p {
  width: 350px;
}
@media (max-width: 1600px) {
  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content p {
    width: 100%;
  }
}
#section-variant-b,
#section-variant-e {
  background-color: #dbd2c4;
  overflow: hidden;
}
#section-variant-b h3,
#section-variant-e h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#section-variant-b ul,
#section-variant-e ul {
  padding-left: 0;
  list-style-type: none;
}
#section-variant-b ul > li,
#section-variant-e ul > li {
  text-indent: -5px;
}
#section-variant-b ul li:before,
#section-variant-e ul li:before {
  content: '- ';
  text-indent: -5px;
}
#section-variant-b:after,
#section-variant-e:after {
  content: ' ';
  background-image: url('../../assets/pattern-b.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 150%;
  z-index: 33;
  position: absolute;
  display: block;
  top: 0px;
  right: -350px;
  opacity: 0.1;
}
#section-variant-b .ant-col:nth-child(1) h2,
#section-variant-e .ant-col:nth-child(1) h2 {
  color: #333;
}
#section-variant-b .ant-col:nth-child(1) img,
#section-variant-e .ant-col:nth-child(1) img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  #section-variant-b .ant-col:nth-child(1) img,
  #section-variant-e .ant-col:nth-child(1) img {
    margin-bottom: 20px;
  }
}
#section-variant-b .ant-col:nth-child(2) p,
#section-variant-e .ant-col:nth-child(2) p {
  margin-top: 20px;
  display: inline-block;
}
#section-variant-b .ant-col:nth-child(2) p:first-child,
#section-variant-e .ant-col:nth-child(2) p:first-child {
  width: calc(30% - 20px);
  margin-right: 20px;
}
#section-variant-b .ant-col:nth-child(2) p:nth-child(2),
#section-variant-e .ant-col:nth-child(2) p:nth-child(2) {
  width: 70%;
}
@media (max-width: 1199px) {
  #section-variant-b .ant-col:nth-child(2) p:nth-child(2),
  #section-variant-e .ant-col:nth-child(2) p:nth-child(2) {
    width: calc(50%);
  }
}
@media (max-width: 991px) {
  #section-variant-b .ant-col:nth-child(2) p:nth-child(2),
  #section-variant-e .ant-col:nth-child(2) p:nth-child(2) {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #section-variant-b .ant-col:nth-child(2) p:nth-child(2),
  #section-variant-e .ant-col:nth-child(2) p:nth-child(2) {
    display: inline-block;
    width: 70%;
  }
}
@media (max-width: 575px) {
  #section-variant-b .ant-col:nth-child(2) p:nth-child(2),
  #section-variant-e .ant-col:nth-child(2) p:nth-child(2) {
    width: 100%;
  }
}
#section-variant-b .ant-col:nth-child(2) p img,
#section-variant-e .ant-col:nth-child(2) p img {
  height: 330px;
  margin-right: 20px;
  object-fit: cover;
  z-index: 2;
  position: relative;
}
.cms-module-column-layout .ant-col ul li {
  font-size: 1rem;
}
#section-variant-e:after {
  left: -350px;
  right: unset;
  transform: rotate(180deg);
  z-index: 1;
}
#section-variant-c {
  background-color: #333;
  overflow: hidden;
}
#section-variant-c h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#section-variant-c:after {
  content: ' ';
  background-image: url('../../assets/pattern-c.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 150%;
  z-index: 33;
  position: absolute;
  display: block;
  top: 0px;
  left: -350px;
  opacity: 0.1;
}
#section-variant-c .ant-col:nth-child(2) p {
  color: white;
}
#section-variant-c .ant-col:nth-child(2) h2 {
  color: white;
}
#section-variant-c .ant-col:nth-child(2) img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  #section-variant-c .ant-col:nth-child(2) img {
    margin-bottom: 20px;
  }
}
#section-variant-c .ant-col:nth-child(1) h3 {
  color: white !important;
}
#section-variant-c .ant-col:nth-child(1) p {
  margin-top: 20px;
  color: white;
  display: inline-block;
}
#section-variant-c .ant-col:nth-child(1) p:first-child {
  width: calc(30% - 20px);
  margin-right: 20px;
}
@media (max-width: 1199px) {
  #section-variant-c .ant-col:nth-child(1) p:first-child {
    width: calc(50% - 20px);
  }
}
@media (max-width: 991px) {
  #section-variant-c .ant-col:nth-child(1) p:first-child {
    display: none;
  }
}
#section-variant-c .ant-col:nth-child(1) p:nth-child(2) {
  width: 70%;
}
@media (max-width: 1199px) {
  #section-variant-c .ant-col:nth-child(1) p:nth-child(2) {
    width: calc(50%);
  }
}
@media (max-width: 991px) {
  #section-variant-c .ant-col:nth-child(1) p:nth-child(2) {
    width: 100%;
  }
}
#section-variant-c .ant-col:nth-child(1) p img {
  height: 330px;
  margin-right: 20px;
  object-fit: cover;
}
#section-default {
  padding-top: 0;
  padding-bottom: 0;
}
#section-default:before {
  content: none;
}
#section-default .ant-col {
  flex: 0 0 35%;
  max-width: 35%;
  background-color: #2c2f2e;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 1199px) {
  #section-default .ant-col {
    flex: 0 0 65%;
    max-width: 65%;
  }
}
@media (max-width: 768px) {
  #section-default .ant-col {
    flex: 0 0 95%;
    max-width: 95%;
  }
}
@media (max-width: 991px) {
  #section-default .ant-col {
    background-color: rgba(44, 47, 46, 0.8);
  }
}
#section-default .cms-module-column-layout-content img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  #section-default .cms-module-column-layout-content img {
    margin-bottom: 20px;
  }
}
#section-variant-d {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
}
#section-variant-d:before {
  content: ' ';
  background-image: url('../../assets/pattern-b.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  right: -350px;
  opacity: 0.1;
  z-index: 1;
}
@media (max-width: 991px) {
}
#section-variant-d .ant-col:nth-child(1):before {
  content: ' ';
  background-image: url('../../assets/bride.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 500px;
  z-index: 33;
  position: absolute;
  display: block;
  top: -300px;
  right: 100px;
}
@media (max-width: 1600px) {
  #section-variant-d .ant-col:nth-child(1):before {
    right: 50px;
    width: 270px;
    top: -250px;
  }
}
@media (max-width: 1199px) {
  #section-variant-d .ant-col:nth-child(1):before {
    display: none;
  }
}
@media (max-width: 768px) {
  #section-variant-d .ant-col:nth-child(1):before {
    right: -50px;
  }
}
#section-variant-d .ant-col:nth-child(1) .cms-module-column-layout-content h2 {
  width: 350px;
}
#section-variant-d .ant-col:nth-child(1) .cms-module-column-layout-content h2:after {
  content: ' ';
  background-image: url('../../assets/pattern-a.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 500px;
  height: 500px;
  z-index: 33;
  position: absolute;
  display: block;
  top: 0px;
  right: 0px;
  opacity: 0.5;
}
@media (max-width: 1199px) {
  #section-variant-d .ant-col:nth-child(1) .cms-module-column-layout-content h2:after {
    display: none;
  }
}
@media (max-width: 768px) {
  #section-variant-d .ant-col:nth-child(1) .cms-module-column-layout-content h2:after {
    top: 20px;
    right: -250px;
  }
}
#section-variant-d .ant-col:nth-child(1) .cms-module-column-layout-content img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
  z-index: 2;
  position: relative;
}
@media (max-width: 768px) {
  #section-variant-d .ant-col:nth-child(1) .cms-module-column-layout-content img {
    margin-bottom: 20px;
  }
}
@media (max-width: 1600px) {
  #section-variant-d .ant-col:nth-child(1) .cms-module-column-layout-content p {
    width: 100%;
  }
}
#section-variant-d .ant-col:nth-child(2) {
  padding-right: 200px;
}
@media (max-width: 1199px) {
  #section-variant-d .ant-col:nth-child(2) {
    padding-right: 20px;
  }
}
#section-variant-d .ant-col:nth-child(2) img {
  z-index: 2;
  position: relative;
}
#section-variant-d .ant-col:nth-child(2) p:first-child img {
  width: 400px;
}
@media (max-width: 768px) {
  #section-variant-d .ant-col:nth-child(2) p:first-child img {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  #section-variant-d .ant-col:nth-child(2) p:nth-child(2) {
    display: none;
  }
}
#section-variant-f {
  background-color: #dbd2c4;
  overflow: hidden;
}
#section-variant-f h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}
#section-variant-f ul {
  padding-left: 0;
  list-style-type: none;
}
#section-variant-f ul > li {
  text-indent: -5px;
}
#section-variant-f ul li:before {
  content: '- ';
  text-indent: -5px;
}
#section-variant-f:after {
  content: ' ';
  background-image: url('../../assets/pattern-b.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 150%;
  z-index: 33;
  position: absolute;
  display: block;
  top: 0px;
  right: -350px;
  opacity: 0.1;
}
#section-variant-f .ant-col:nth-child(2) h2 {
  color: #333;
}
#section-variant-f .ant-col:nth-child(2) img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  #section-variant-f .ant-col:nth-child(2) img {
    margin-bottom: 20px;
  }
}
#section-variant-f .ant-col:nth-child(1) p {
  margin-top: 20px;
  display: inline-block;
}
#section-variant-f .ant-col:nth-child(1) p:first-child {
  width: calc(30% - 20px);
  margin-right: 20px;
}
#section-variant-f .ant-col:nth-child(1) p:nth-child(2) {
  width: 70%;
}
@media (max-width: 1199px) {
  #section-variant-f .ant-col:nth-child(1) p:nth-child(2) {
    width: calc(50%);
  }
}
@media (max-width: 991px) {
  #section-variant-f .ant-col:nth-child(1) p:nth-child(2) {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #section-variant-f .ant-col:nth-child(1) p:nth-child(2) {
    display: inline-block;
    width: 70%;
  }
}
@media (max-width: 575px) {
  #section-variant-f .ant-col:nth-child(1) p:nth-child(2) {
    width: 100%;
  }
}
#section-variant-f .ant-col:nth-child(1) p img {
  height: 330px;
  margin-right: 20px;
  object-fit: cover;
  z-index: 2;
  position: relative;
}
#section-variant-g {
  background-color: #2c2f2e;
  padding: 100px;
  padding-left: 200px;
  padding-right: 200px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1600px) {
  #section-variant-g {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (max-width: 768px) {
  #section-variant-g {
    padding-left: 50px;
    padding-right: 50px;
  }
}
#section-variant-g > .ant-col > .ant-row > .ant-col:nth-child(1) {
  padding-right: 50px;
}
#section-variant-g > .ant-col > .ant-row > .ant-col:nth-child(1) img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
  z-index: 2;
  position: relative;
}
@media (max-width: 768px) {
  #section-variant-g > .ant-col > .ant-row > .ant-col:nth-child(1) img {
    margin-bottom: 20px;
  }
}
#section-variant-g h2 {
  color: white !important;
}
#section-variant-g h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #bd9557 !important;
}
#section-variant-g p {
  color: white;
}
#section-variant-g:before {
  content: ' ';
  background-image: url('../../assets/pattern-c.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 150%;
  z-index: 33;
  position: absolute;
  display: block;
  top: 0px;
  right: -350px;
  opacity: 0.1;
}
#section-variant-g .ant-carousel {
  margin-bottom: 30px;
}
#section-variant-g.cms-module-slider img {
  height: 400px;
}
#section-variant-h {
  background-color: #dbd2c4;
  padding: 100px;
  padding-left: 200px;
  padding-right: 200px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1600px) {
  #section-variant-h {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (max-width: 768px) {
  #section-variant-h {
    padding-left: 50px;
    padding-right: 50px;
  }
}
#section-variant-h > .ant-col > .ant-row > .ant-col:nth-child(2) {
  padding-left: 50px;
}
@media (max-width: 991px) {
  #section-variant-h > .ant-col > .ant-row > .ant-col:nth-child(2) {
    padding-left: 0;
  }
}
#section-variant-h > .ant-col > .ant-row > .ant-col:nth-child(2) img {
  height: 20px;
  width: auto;
  margin-bottom: 50px;
  z-index: 2;
  position: relative;
}
@media (max-width: 768px) {
  #section-variant-h > .ant-col > .ant-row > .ant-col:nth-child(2) img {
    margin-bottom: 20px;
  }
}
#section-variant-h h2 {
  color: unset !important;
}
#section-variant-h h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #bd9557 !important;
}
#section-variant-h:before {
  content: ' ';
  background-image: url('../../assets/pattern-c.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 150%;
  z-index: 33;
  position: absolute;
  display: block;
  top: 0px;
  left: -350px;
  opacity: 0.1;
}
#section-variant-h .ant-carousel {
  margin-bottom: 30px;
}
#section-variant-h.cms-module-slider img {
  height: 400px;
}
.ant-carousel .slick-next::before,
.slick-prev::before {
  display: inline-block;
  background-color: #bd9557;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: white;
  font-size: 20px;
}
.slick-next,
.slick-prev {
  width: unset !important;
  height: unset !important;
}
.slick-prev::before {
  left: -15px;
  position: relative;
  content: '<' !important;
}
.slick-next::before {
  content: '>' !important;
}
.ant-carousel {
  position: relative;
  z-index: 999;
  padding-right: 10px;
}
.ant-carousel .slick-next {
  right: -40px;
}
.slider-content {
  display: none;
}
.slick-slider button {
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}
.slick-slider button:hover {
  transition: opacity 0.3s ease-in-out;
  opacity: 0.7;
}
.page-over-mij #section-variant-a .ant-col:nth-child(1) p:first-child img {
  width: unset;
  max-width: 100%;
}
.page-over-mij .content-header img.single-bg {
  object-position: center 32%;
}
.page-over-mij #section-variant-a .ant-col:nth-child(2):before {
  display: none;
}
.page-over-mij #section-variant-a:before {
  display: none !important;
}
.page-over-mij #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2:after {
  display: none;
}
.page-over-mij #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content p {
  width: unset;
  max-width: 600px;
}
.page-over-mij #section-variant-a .ant-col:nth-child(1) {
  padding-left: 20px;
}
.page-over-mij #section-variant-a .ant-col:nth-child(1):before {
  top: 10px;
  display: none;
}
.page-over-mij .section-default .react-parallax-bgimage {
  top: -59%;
}
@media (max-width: 1600px) {
  .page-over-mij .section-default .react-parallax-bgimage {
    top: -40%;
  }
}
@media (max-width: 1199px) {
  .page-over-mij .section-default .react-parallax-bgimage {
    top: -30%;
  }
}
@media (max-width: 991px) {
  .page-over-mij .section-default .react-parallax-bgimage {
    top: -20%;
  }
}
@media (max-width: 768px) {
  .page-over-mij .section-default .react-parallax-bgimage {
    top: -15%;
  }
}
.page-over-mij #section-default .ant-col {
  background-color: rgba(44, 47, 46, 0.7);
}
#header-navbar .ant-dropdown {
  z-index: 3000;
}
#header-navbar #header-main-hamburger span {
  background: #bd9557 !important;
}
#header-navbar #header-main-logo img {
  object-fit: contain;
}
.page-home .react-parallax.section-promo .react-parallax-bgimage {
  top: -150px;
  left: calc(50% + 150px) !important;
}
@media (max-width: 768px) {
  .page-home #section-variant-b {
    background-color: transparent;
  }
}
.page-home #section-variant-b .ant-col:nth-child(2) p:first-child,
.page-home #section-variant-e .ant-col:nth-child(2) p:first-child {
  width: 100%;
}
.page-events #section-default {
  padding-top: 50px;
  padding-bottom: 50px;
}
.page-events #section-default .ant-col {
  background-color: rgba(44, 47, 46, 0.9);
}
.page-events #section-variant-a .ant-col:nth-child(2):before {
  top: -280px;
  background-image: url('../../assets/new-york.jpeg');
}
.page-events #section-variant-g .ql-align-center {
  margin-bottom: 0;
}
.page-events #section-variant-g h2 {
  margin-bottom: 20px !important;
}
.page-events #section-variant-g .slider-slide {
  padding: 5px;
}
.page-events #section-variant-g .ant-carousel {
  margin-top: 40px;
  margin-bottom: 0;
}
.page-events #section-variant-g .ant-row {
  width: 100%;
}
.page-events #section-variant-g .button-row {
  text-align: center;
}
@media (max-width: 991px) {
  .page-events #section-variant-f .ant-col {
    flex: 0 0 85%;
    max-width: 85%;
  }
}
.page-events #section-variant-f .ant-col:nth-child(1) p img {
  height: 400px;
}
@media (max-width: 768px) {
  .page-events #section-variant-f .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.page-events #section-variant-f .ant-col:nth-child(1) p:nth-child(2) img {
  height: 20px;
  width: auto;
}
.page-events #section-variant-f p {
  width: 100%;
  margin-top: 0 !important;
}
#section-variant-i h2 {
  color: #333;
}
#section-variant-i img {
  width: auto;
  height: 20px;
}
.page-wedding #section-variant-a {
  overflow: hidden;
}
.page-wedding #section-variant-a .ant-col:nth-child(2):before {
  content: ' ';
  background-image: url('../../assets/bride.jpg');
}
@media (max-width: 1199px) {
  .page-wedding #section-variant-a .ant-col:nth-child(2):before {
    display: none;
  }
}
.page-wedding #section-variant-a:after {
  content: ' ';
  background-image: url('../../assets/wedding-white.jpeg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 300px;
  height: 500px;
  z-index: 33;
  position: absolute;
  display: block;
  bottom: -20px;
  right: 100px;
}
@media (max-width: 1600px) {
  .page-wedding #section-variant-a:after {
    display: none;
  }
}
.page-wedding #section-variant-f .ant-col:nth-child(1) p:first-child {
  width: 100%;
}
.page-portfolio #section-variant-a {
  background-color: #dbd2c4;
}
.page-portfolio #section-variant-a .ant-col:first-child p:first-child img {
  width: 100%;
}
.page-portfolio #section-variant-a .ant-col:nth-child(2):before {
  display: none;
}
.page-portfolio #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2:after {
  display: none;
}
.page-portfolio #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content p {
  width: 100%;
  max-width: 600px;
}
.page-portfolio #section-variant-g {
  background-color: white;
}
.page-portfolio #section-variant-g h2,
.page-portfolio #section-variant-g p {
  color: #333 !important;
}
.page-portfolio #section-variant-g:before {
  background-image: url('../../assets/pattern-b.png');
}
.page-portfolio #section-variant-h {
  background-color: #2c2f2e;
}
.page-portfolio #section-variant-h:before {
  transform: scaleX(-1);
}
.page-portfolio #section-variant-h h2,
.page-portfolio #section-variant-h p {
  color: white !important;
}
.page-portfolio #section-variant-g p img,
.page-portfolio #section-variant-h p img {
  width: auto;
  height: 20px;
  margin-bottom: 20px !important;
}
.page-portfolio #section-variant-g .slider-slide img,
.page-portfolio #section-variant-h .slider-slide img {
  height: unset !important;
  object-fit: contain !important;
}
.page-portfolio #section-variant-h > .ant-col > .ant-row > .ant-col:nth-child(2) img {
  height: unset !important;
  width: 100% !important;
  object-fit: contain !important;
}
.page-portfolio #section-variant-h > .ant-col > .ant-row > .ant-col:nth-child(2) {
  padding-left: 0;
}
