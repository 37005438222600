@import (once) 'breakpoints.less';
@import (once) '../../custom/styles/theme.less';

.products-container {
  min-height: 55vh;
  padding-top: @theme-section-padding;
  padding-bottom: @theme-section-padding;

  @media @tablet-down {
    padding-top: @theme-section-padding-mobile;
    padding-bottom: @theme-section-padding-mobile;
  }

  .ant-slider-mark-text {
    display: inline-block;
    min-width: 100px;
  }

  .no-result-container {
    padding-top: 80px;
    @media @tablet-down {
      padding-top: unset;
    }
  }

  h2 {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .product-col {
    .product {
      transform: translateZ(0); // bug fix: safari shows border-radius on image hover
      overflow: hidden;
      border-radius: 15px;
      position: relative;
      height: auto;
      width: 100%;
      display: inline-block;
      border: 1px solid #aaa;
      -webkit-box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 0.52);
      -moz-box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 0.52);
      box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 0.52);

      .product-image {
        height: 400px;
        overflow: hidden;
        position: relative;
        @media @tablet-down {
          height: 200px;
        }

        .stock-message {
          background-color: @theme-color-highlight;
          color: @theme-color-black;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 35px;
          text-align: center;
          z-index: 2;
          line-height: 35px;

          &.sold-out {
            background-color: rgba(255, 0, 0, 0.8);
            color: white;
          }
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: transform 0.3s ease-out;
        }

        &:hover {
          img {
            transform: scale(1.1);
            transition: transform 0.3s ease-in;
          }
        }
      }

      .product-details {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        color: black;
      }

      .product-actions {
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 10px;
        padding-right: 10px;

        button {
          float: right;
          @media @tablet-down {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.products-filters {
  //margin-bottom: 20px;
  .products-filter {
    margin-bottom: 30px;
    &-label {
      border-bottom: 1px #aaa solid;
      margin-bottom: 20px;

      .unit-link {
        float: right;
        margin-right: 5px;
        color: @theme-color;
        text-decoration: underline;
        transition: color 0.2s ease-in-out;
        &:hover {
          transition: color 0.2s ease-in-out;
          color: @theme-color-light;
          cursor: pointer;
        }
      }
    }
  }
}

#wishlist-modal {
  padding: 50px;
  padding-bottom: 0;
  margin-bottom: 0 !important;
  @media @tablet-down {
    padding-left: 0px;
    padding-right: 0px;
  }
  h2 {
    text-transform: uppercase;
    font-size: 0.8rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .no-result-container {
    padding-top: 0px;
  }
  .ant-btn {
    line-height: unset;
  }
}
