@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';
//
///////////////////// MAIN /////////////////
/////////////////////////////////////////////////////

///////////////////// END MAIN /////////////////
/////////////////////////////////////////////////////

* {
  font-family: 'Lora', sans-serif;
  font-variant: none;
}

.ant-btn {
  background-color: @theme-color-gold;
  border-color: @theme-color-gold;
  border-radius: 10px;
  &:hover {
    background-color: @theme-color-gold-highlight;
    border-color: @theme-color-gold-highlight;
  }
}

footer #copyright img {
  background-color: #333;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

footer {
  .footer-link {
    @media @desktop-down {
      padding-left: 0 !important;
    }
  }
}

h2 {
  font-size: 2rem;
  color: @theme-color-gold;
}

h3 {
  color: #333 !important;
}

#section-promo {
  position: relative;
  &:before {
    background-image: url('../../assets/wave-vertical.png');
    background-repeat: no-repeat;
    opacity: 1;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1200px;
    background-size: contain;
    background-position: -150px;

    @media @extra-large-desktop-down {
      left: -120px;
    }

    @media @large-desktop-down {
      left: -200px;
    }

    @media @desktop-down {
      opacity: 0.6;
    }

    @media @tablet-down {
      background-size: cover;
      background-position: 0;
    }
  }

  align-items: flex-start;
  justify-content: flex-start;

  h1 {
    width: 330px;
    color: #333;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 50px;
    @media @large-desktop-down {
      width: 100%;
    }
  }

  img {
    height: 20px;
    width: auto;
    margin-bottom: 50px;
    @media @tablet-down {
      margin-bottom: 20px;
    }
  }

  p {
    display: block;
    width: 500px;
    color: #333;

    @media @large-desktop-down {
      width: 400px;
    }

    @media @tablet-down {
      width: 100%;
    }
  }
}

////////////////////////////
// DEFAULT SECTIONS
////////////////////////////

#section-variant-a {
  overflow: hidden;

  background-repeat: no-repeat;
  background-size: contain;

  @media @tablet-down {
    background-size: auto 120%;
  }

  .ant-col:nth-child(1) {
    padding-left: 200px;
    @media @desktop-down {
      padding-left: 50px;
    }

    &:before {
      content: ' ';
      background-image: url('../../assets/pattern-a.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 500px;
      height: 500px;
      z-index: 33;
      position: absolute;
      display: block;
      top: 150px;
      left: -150px;
      opacity: 0.5;

      @media @desktop-down {
        top: 50px;
      }
    }

    p:first-child {
      img {
        width: 400px;
        @media @tablet-down {
          width: 80%;
          float: right;
        }
      }
    }
    p:nth-child(2) {
      @media @desktop-down {
        display: none;
      }
    }
  }

  .ant-col:nth-child(2) {
    &:before {
      content: ' ';
      background-image: url('../../assets/wedding-white.jpeg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 300px;
      height: 500px;
      z-index: 33;
      position: absolute;
      display: block;
      top: -250px;
      right: 100px;

      @media @extra-large-desktop-down {
        right: 20px;
        width: 270px;
        top: -280px;
      }

      @media @large-desktop-down {
        content: none;
      }

      @media @desktop-down {
        content: none;
      }
    }


    //&:before {
    //  content: ' ';
    //  background-image: url('../../assets/wedding-white.jpeg');
    //  background-repeat: no-repeat;
    //  background-size: contain;
    //  width: 300px;
    //  height: 500px;
    //  z-index: 33;
    //  position: absolute;
    //  display: block;
    //  top: -550px;
    //  right: 100px;
    //
    //  @media @extra-large-desktop-down {
    //    right: 20px;
    //    width: 270px;
    //    top: -280px;
    //  }
    //
    //  @media @large-desktop-down {
    //    content: none;
    //  }
    //
    //  @media @desktop-down {
    //    content: none;
    //  }
    //}

    .cms-module-column-layout-content {
      h2 {
        width: 350px;
        &:after {
          content: ' ';
          background-image: url('../../assets/pattern-a.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 500px;
          height: 500px;
          z-index: 33;
          position: absolute;
          display: block;
          top: 100px;
          right: 0px;
          opacity: 0.3;

          @media @extra-large-desktop-down {
            top: 0px;
            width: 450px;
            right: -100px;
          }

          @media @large-desktop-down {
            content: none;
          }
        }
      }

      img {
        height: 20px;
        width: auto;
        margin-bottom: 50px;
        @media @tablet-down {
          margin-bottom: 20px;
        }
      }

      p {
        width: 350px;
        @media @extra-large-desktop-down {
          width: 100%;
        }
      }
    }
  }
}

#section-variant-b,
#section-variant-e {
  background-color: #dbd2c4;
  overflow: hidden;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  ul > li {
    text-indent: -5px;
  }
  ul li:before {
    content: '- ';
    text-indent: -5px;
  }

  &:after {
    content: ' ';
    background-image: url('../../assets/pattern-b.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 150%;
    z-index: 33;
    position: absolute;
    display: block;
    top: 0px;
    right: -350px;
    opacity: 0.1;
  }

  .ant-col:nth-child(1) {
    h2 {
      color: #333;
    }

    img {
      height: 20px;
      width: auto;
      margin-bottom: 50px;
      @media @tablet-down {
        margin-bottom: 20px;
      }
    }
  }

  .ant-col:nth-child(2) {
    p {
      margin-top: 20px;
      display: inline-block;
      &:first-child {
        width: calc(30% - 20px);
        margin-right: 20px;
        //@media @large-desktop-down {
        //  width: calc(50% - 20px);
        //}
        //
        //@media @desktop-down {
        //  display: none;
        //}
        //
        //@media @tablet-down {
        //  display: inline-block;
        //  width: calc(30% - 20px);
        //}
        //
        //@media @phone-down {
        //  display: none;
        //}
      }
      &:nth-child(2) {
        width: 70%;
        @media @large-desktop-down {
          width: calc(50%);
        }
        @media @desktop-down {
          width: 100%;
        }

        @media @tablet-down {
          display: inline-block;
          width: 70%;
        }

        @media @phone-down {
          width: 100%;
        }
      }

      img {
        height: 330px;
        margin-right: 20px;
        object-fit: cover;
        z-index: 2;
        position: relative;
      }
    }
  }
}

.cms-module-column-layout .ant-col ul li {
  font-size: 1rem;
}

#section-variant-e {
  &:after {
    left: -350px;
    right: unset;
    transform: rotate(180deg);
    z-index: 1;
  }
}

#section-variant-c {
  background-color: #333;
  overflow: hidden;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &:after {
    content: ' ';
    background-image: url('../../assets/pattern-c.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 150%;
    z-index: 33;
    position: absolute;
    display: block;
    top: 0px;
    left: -350px;
    opacity: 0.1;
  }

  .ant-col:nth-child(2) {
    p {
      color: white;
    }

    h2 {
      color: white;
    }

    img {
      height: 20px;
      width: auto;
      margin-bottom: 50px;
      @media @tablet-down {
        margin-bottom: 20px;
      }
    }
  }

  .ant-col:nth-child(1) {
    h3 {
      color: white !important;
    }

    p {
      margin-top: 20px;
      color: white;
      display: inline-block;
      &:first-child {
        width: calc(30% - 20px);
        margin-right: 20px;
        @media @large-desktop-down {
          width: calc(50% - 20px);
        }
        @media @desktop-down {
          display: none;
        }
      }
      &:nth-child(2) {
        width: 70%;
        @media @large-desktop-down {
          width: calc(50%);
        }
        @media @desktop-down {
          width: 100%;
        }
      }

      img {
        height: 330px;
        margin-right: 20px;
        object-fit: cover;
      }
    }
  }
}

#section-default {
  padding-top: 0;
  padding-bottom: 0;

  &:before {
    content: none;
  }

  .ant-col {
    flex: 0 0 35%;
    max-width: 35%;

    @media @large-desktop-down {
      flex: 0 0 65%;
      max-width: 65%;
    }

    @media @tablet-down {
      flex: 0 0 95%;
      max-width: 95%;
    }

    background-color: #2c2f2e;
    @media @desktop-down {
      background-color: rgba(44, 47, 46, 0.8);
    }
    padding: 50px;
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;
  }

  .cms-module-column-layout-content {
    img {
      height: 20px;
      width: auto;
      margin-bottom: 50px;
      @media @tablet-down {
        margin-bottom: 20px;
      }
    }
  }
}

#section-variant-d {
  overflow: hidden;

  //background-size: 60% 100%;
  background-repeat: no-repeat;
  background-size: contain;

  &:before {
    content: ' ';
    background-image: url('../../assets/pattern-b.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 100%;
    position: absolute;
    display: block;
    top: 0px;
    right: -350px;
    opacity: 0.1;
    z-index: 1;
    @media @desktop-down {
      //top: 0px;
    }
  }

  .ant-col:nth-child(1) {
    &:before {
      content: ' ';
      background-image: url('../../assets/bride.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 300px;
      height: 500px;
      z-index: 33;
      position: absolute;
      display: block;
      top: -300px;
      right: 100px;

      @media @extra-large-desktop-down {
        right: 50px;
        width: 270px;
        top: -250px;
      }
      @media @large-desktop-down {
        display: none;
      }

      @media @tablet-down {
        right: -50px;
      }
    }

    .cms-module-column-layout-content {
      h2 {
        width: 350px;
        &:after {
          content: ' ';
          background-image: url('../../assets/pattern-a.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 500px;
          height: 500px;
          z-index: 33;
          position: absolute;
          display: block;
          top: 0px;
          right: 0px;
          opacity: 0.5;

          @media @large-desktop-down {
            display: none;
          }
          @media @tablet-down {
            top: 20px;
            right: -250px;
          }
        }
      }

      img {
        height: 20px;
        width: auto;
        margin-bottom: 50px;
        @media @tablet-down {
          margin-bottom: 20px;
        }
        z-index: 2;
        position: relative;
      }

      p {
        //width: 350px;
        @media @extra-large-desktop-down {
          width: 100%;
        }
      }
    }
  }


  .ant-col:nth-child(2) {
    padding-right: 200px;

    @media @large-desktop-down {
      padding-right: 20px;
    }

    img {
      z-index: 2;
      position: relative;
    }

    p:first-child {
      img {
        width: 400px;
        @media @tablet-down {
          width: 100%;
          margin-top: 20px;
          margin-bottom: 0;
        }
      }
    }
    p:nth-child(2) {
      @media @desktop-down {
        display: none;
      }
    }
  }

}





#section-variant-f {
  background-color: #dbd2c4;
  overflow: hidden;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  ul > li {
    text-indent: -5px;
  }
  ul li:before {
    content: '- ';
    text-indent: -5px;
  }

  &:after {
    content: ' ';
    background-image: url('../../assets/pattern-b.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 150%;
    z-index: 33;
    position: absolute;
    display: block;
    top: 0px;
    right: -350px;
    opacity: 0.1;
  }

  .ant-col:nth-child(2) {
    h2 {
      color: #333;
    }

    img {
      height: 20px;
      width: auto;
      margin-bottom: 50px;
      @media @tablet-down {
        margin-bottom: 20px;
      }
    }
  }

  .ant-col:nth-child(1) {
    p {
      margin-top: 20px;
      display: inline-block;
      &:first-child {
        width: calc(30% - 20px);
        margin-right: 20px;
        //@media @large-desktop-down {
        //  width: calc(50% - 20px);
        //}
        //
        //@media @desktop-down {
        //  display: none;
        //}
        //
        //@media @tablet-down {
        //  display: inline-block;
        //  width: calc(30% - 20px);
        //}
        //
        //@media @phone-down {
        //  display: none;
        //}
      }
      &:nth-child(2) {
        width: 70%;
        @media @large-desktop-down {
          width: calc(50%);
        }
        @media @desktop-down {
          width: 100%;
        }

        @media @tablet-down {
          display: inline-block;
          width: 70%;
        }

        @media @phone-down {
          width: 100%;
        }
      }

      img {
        height: 330px;
        margin-right: 20px;
        object-fit: cover;
        z-index: 2;
        position: relative;
      }
    }
  }
}

#section-variant-g {
  background-color: #2c2f2e;
  padding: 100px;
  padding-left: 200px;
  padding-right: 200px;
  position: relative;
  overflow: hidden;

  @media @extra-large-desktop-down {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media @tablet-down {
    padding-left: 50px;
    padding-right: 50px;
  }



  > .ant-col > .ant-row > .ant-col:nth-child(1) {
    padding-right: 50px;

    img {
      height: 20px;
      width: auto;
      margin-bottom: 50px;
      @media @tablet-down {
        margin-bottom: 20px;
      }
      z-index: 2;
      position: relative;
    }
  }

  h2 {
    color: white !important;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: @theme-color !important;
  }

  p {
    color: white;
  }

  &:before {
    content: ' ';
    background-image: url('../../assets/pattern-c.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 150%;
    z-index: 33;
    position: absolute;
    display: block;
    top: 0px;
    right: -350px;
    opacity: 0.1;
  }

  .ant-carousel {
    margin-bottom: 30px;
  }


  &.cms-module-slider img {
    height: 400px;
  }
}

#section-variant-h {
  background-color: #dbd2c4;
  padding: 100px;
  padding-left: 200px;
  padding-right: 200px;
  position: relative;
  overflow: hidden;


  @media @extra-large-desktop-down {
    padding-left: 100px;
    padding-right: 100px;
  }

  @media @tablet-down {
    padding-left: 50px;
    padding-right: 50px;
  }

  > .ant-col > .ant-row > .ant-col:nth-child(2) {
    padding-left: 50px;

    @media @desktop-down {
      padding-left: 0;
    }

    img {
      height: 20px;
      width: auto;
      margin-bottom: 50px;
      @media @tablet-down {
        margin-bottom: 20px;
      }
      z-index: 2;
      position: relative;
    }
  }

  h2 {
    color: unset !important;
  }

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: @theme-color !important;
  }

  &:before {
    content: ' ';
    background-image: url('../../assets/pattern-c.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 500px;
    height: 150%;
    z-index: 33;
    position: absolute;
    display: block;
    top: 0px;
    left: -350px;
    opacity: 0.1;
  }

  .ant-carousel {
    margin-bottom: 30px;
  }


  &.cms-module-slider img {
    height: 400px;
  }
}



.ant-carousel .slick-next::before,.slick-prev::before {
  display: inline-block;
  background-color: @theme-color;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: white;
  font-size: 20px;
}

.slick-next, .slick-prev {
  width: unset !important;
  height: unset !important;
}

.slick-prev::before {
  left: -15px;
  position: relative;
  content: '<' !important;
}

.slick-next::before {
  content: '>' !important;
}

.ant-carousel {
  position: relative;
  z-index: 999;
  padding-right: 10px;
  .slick-next {
    right: -40px;
  }
}

.slider-content {
  display: none;
}

.slider-slide {
  //padding-right: 10px;
}

.slick-slider {
  button {
    transition: opacity 0.3s ease-in-out;
    z-index: 999;
    &:hover {
      transition: opacity 0.3s ease-in-out;
      opacity: 0.7;
    }
  }
}

.page-over-mij {
  #section-variant-a .ant-col:nth-child(1) p:first-child img {
    width: unset;
    max-width: 100%;
  }
  .content-header img.single-bg {
    object-position: center 32%;
  }

  #section-variant-a .ant-col:nth-child(2):before {
    display: none;
  }

  #section-variant-a {
    &:before {
      display: none !important;
    }
  }

  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2:after {
    display: none;
  }

  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content p {
    width: unset;
    max-width: 600px;
  }

  #section-variant-a .ant-col:nth-child(1) {
    padding-left: 20px;
  }

  #section-variant-a .ant-col:nth-child(1):before {
    top: 10px;
    //@media @tablet-down {
     display: none;
    //}
  }

  .section-default {
    .react-parallax-bgimage {
      top: -59% ;
      @media @extra-large-desktop-down {
        top: -40% ;
      }
      @media @large-desktop-down {
        top: -30% ;
      }
      @media @desktop-down {
        top: -20% ;
      }
      @media @tablet-down {
        top: -15% ;
      }
    }
  }

  #section-default {



    .ant-col {
      background-color: rgba(44, 47, 46, 0.7);
    }

  }
}