@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme';

.cms-module {
  &-paragraph {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;

    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    h2 {
      color: @theme-color;
    }

    h3 {
      color: @theme-color;
      border-bottom: 1px solid #333;
    }

    p {
      margin-bottom: 0;
    }

    a {
      color: inherit;
    }
  }
}
