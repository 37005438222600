@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme';

.cms-module {
  &-centered-paragraph {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;
    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    text-align: center;
    h2 {
      text-align: center;
      margin-bottom: 0px;
    }

    p {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .cms-module-button-row {
      text-align: center;
    }
  }
}
