@import (once) '../breakpoints.less';

.cms-module {
  &-text-with-picture-block {
    padding-top: 40px;
    padding-bottom: 0px;
    .text-with-picture-block {
      &-description {
        padding: 40px;
        background-color: rgba(245, 245, 245, 0.5);
      }
    }

    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
      position: relative;
      top: -50px;
    }

    button {
      margin-bottom: 40px;
    }
  }
}
