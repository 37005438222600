@import (once) '../breakpoints.less';

.cms-module {
  &-slider {
    padding-top: 20px;

    @media @tablet-down {
      .slick-dots {
        display: none !important;
      }
    }

    @media @tablet-down {
      hr {
        margin-top: 40px;
      }
    }

    .slider-title {
      padding: 20px;
      padding-left: 40px;
      @media @tablet-down {
        padding-bottom: 0;
        padding-left: 20px;
      }

      h2 {
        //font-size: 2rem;
        font-style: italic;
        //line-height: 2.5rem;
        @media @tablet-down {
          margin-bottom: 10px;
          font-size: 1.7rem;
          line-height: 1.7rem;
        }
      }
    }

    .ant-anchor-wrapper {
      width: auto;
      background-color: unset;

      .ant-anchor-link {
        display: inline-block;
        margin-right: 10px;
        background-color: black;
        border-color: black;
        height: 40px;
        width: 200px;
        max-width: 200px;
        transition: background-color 0.2s ease-in-out;
        &:hover {
          cursor: pointer;
          background-color: rgba(15, 168, 200, 1);
          border-color: rgba(15, 168, 200, 1);
          transition: background-color 0.2s ease-in-out;
        }
        &-title {
          color: white;
          line-height: 25px;
          text-decoration: none;
        }
      }
      .ant-anchor-ink {
        display: none;
      }
    }

    .slider-subtitle {
      font-size: 1rem;
      padding-left: 20px;
    }

    .slider-button {
      text-align: center;
    }

    .ant-carousel {
      height: auto;
      width: 100%;
    }

    img {
      height: 650px;
      width: 100%;
      object-fit: cover;
      margin: 0 auto;
      @media @tablet-down {
        height: 600px;
      }
    }

    .slider-slide {
      position: relative;

      .slider-content {
        position: absolute;
        height: 176px;
        bottom: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.73);
      }
    }
  }
}
