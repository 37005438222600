@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.cms-module {
  &-instagram-feed {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;
    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    h2,
    h3 {
      text-align: center;
    }

    &-container {
      height: 350px;
      @media @tablet-down {
        height: 300px;
      }
      @media @phone-down {
        height: 250px;
      }

      .ant-carousel {
        width: 100%;
        height: 100%;
        .slick-slider {
          height: 100%;
          .slick-list {
            height: 100%;
            .slick-track {
              left: unset !important;
              padding-left: 25px;
              height: 100%;
              width: 100% !important;
              .slick-slide {
                height: 100%;
                //width: 25% !important;

                //padding-left: 25px !important;
                padding-right: 25px !important;

                //img {
                //  width: 25%;
                //}

                div {
                  height: 100%;
                }
              }
            }
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
        transition: 0.3s opacity ease-in-out;
        &:hover {
          opacity: 0.8;
          transition: 0.3s opacity ease-in-out;
          cursor: pointer;
        }
      }
    }
  }
}
