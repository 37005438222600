@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.cms-module {
  &-newsletter-signup {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;

    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    h2 {
      text-align: center;
    }

    &.has-bg-image {
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.4;
      }

      h2,
      em,
      p {
        color: white;
      }
    }

    #cms-module-newsletter-signup-description {
      text-align: center !important;
    }

    @media @tablet-down {
      padding-left: 20px;
      padding-right: 20px;
    }

    #cms-module-newsletter-signup-button {
      text-align: center;
    }
  }
}
