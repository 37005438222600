@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';
//
.cms-module-grid {


  .column-with-image {
    .column-content {
      height: 100%;
      p {
        height: 100%;
        margin-bottom: 0;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .cms-module-grid-title-container {
    padding-left: 60px;
    margin-top: @theme-section-padding;
    @media @tablet-down {
      margin-top: @theme-section-padding-mobile;
    }
    @media @phone-down {
      padding-left: 40px;
    }
  }

  .column-with-boxes {
    padding: 40px;

    @media @tablet-down {
      padding-top: 0;
    }


    .column-content {
      @media @phone-down {
        background-color: #e7dfd2;
        padding:20px;
        //height: unset;
      }
      text-align: left;
      border: none;
      color: black;
      //height: 400px;
      p {
        text-align: left;
      }
    }

    button {

      @media @extra-large-desktop-down {
        width: 90%;
      }
      @media @large-desktop-down {
        width: 50%;
      }
      @media @tablet-down {
        //left: 20px;
        position: relative;
        bottom: unset;
        width: 100%;
      }
      //position: absolute;
      //bottom: 50px;
      left: 0;
      width: 50%;
      //padding-left: 10px;
      //padding-right: 10px;
      //padding-top: 10px;
      //padding-bottom: 10px;
      border: 1px solid @theme-color !important;
      background-color: transparent;
      color: @theme-color !important;
      //height: unset;
      &:hover {
        color: #aaa !important;
        border: 1px solid #aaa !important;
      }
    }

  }


  .column-with-boxes {
    .column-content {
      margin-bottom: 40px;
      padding: 20px;
      //padding-left: 0;
    }
  }
}
//  padding-top: @theme-section-padding;
//  padding-bottom: @theme-section-padding;
//
//  @media @tablet-down {
//    padding-top: @theme-section-padding-mobile;
//    padding-bottom: @theme-section-padding-mobile;
//  }
//
//  padding-left: 20px;
//  padding-right: 20px;
//
//  &-title {
//    text-align: center;
//  }
//
//  &-subtitle {
//    text-align: center;
//    p {
//      margin-bottom: 0;
//    }
//    margin-bottom: 50px;
//  }
//
//  &-four-to-one,
//  &-one-to-four {
//    .column-with-image {
//      .column-content {
//        height: 100%;
//        position: relative;
//
//        p {
//          height: 100%;
//          margin-bottom: 0;
//        }
//
//        a {
//          opacity: 1;
//          transition: 0.3s opacity ease-in-out;
//
//          &:hover {
//            transition: 0.3s opacity ease-in-out;
//            opacity: 0.8;
//            cursor: pointer;
//          }
//        }
//
//        img {
//          max-width: 100%;
//          height: 100%;
//          object-fit: cover;
//          display: block;
//          position: absolute;
//          top: 50%;
//          left: 50%;
//          min-height: 100%;
//          min-width: 100%;
//          transform: translate(-50%, -50%);
//
//          @media @large-desktop-down {
//            height: 400px;
//            width: 100%;
//            position: relative;
//            padding-bottom: 20px;
//          }
//
//          @media @tablet-down {
//            padding-right: 0;
//          }
//        }
//      }
//    }
//
//    .column-with-boxes {
//      .ant-col {
//        height: calc(50% - 10px);
//
//        &:nth-child(1),
//        &:nth-child(2) {
//          margin-bottom: 10px;
//        }
//
//        &:nth-child(4),
//        &:nth-child(3) {
//          margin-top: 10px;
//        }
//
//        .column-content {
//          border: 1px solid @theme-color;
//          height: calc(100%);
//          padding: 40px;
//          color: white;
//          display: inline-grid;
//          align-items: center;
//
//          text-align: center;
//
//          @media @large-desktop-down {
//            margin-left: 0;
//            margin-bottom: 20px;
//          }
//
//          @media @tablet-down {
//            display: inline-block;
//            margin-right: 0;
//            padding-bottom: 40px;
//            margin-bottom: 10px;
//          }
//
//          .ant-btn-primary {
//            margin-top: 20px;
//            margin-bottom: 20px;
//            background-color: white !important;
//            color: @theme-color-black !important;
//            border-color: white !important;
//            &:hover {
//              color: #aaa !important;
//            }
//          }
//        }
//      }
//    }
//  }
//
//  &-one-to-four {
//    .column-with-image {
//      .column-content {
//        margin-right: 10px;
//        @media @large-desktop-down {
//          margin-right: 0;
//        }
//      }
//    }
//
//    .column-with-boxes {
//      height: 100%;
//      @media (max-width: 575px) {
//        height: unset;
//      }
//
//      .ant-col {
//        &:nth-child(odd) {
//          padding-right: 5px;
//          @media (max-width: 575px) {
//            padding-right: 0;
//          }
//        }
//
//        &:nth-child(even) {
//          padding-left: 5px;
//          @media (max-width: 575px) {
//            padding-left: 0;
//          }
//        }
//
//        .column-content {
//          margin-left: 10px;
//          @media @large-desktop-down {
//            margin-left: 0;
//          }
//        }
//      }
//    }
//  }
//
//  &-four-to-one {
//    .column-with-image {
//      .column-content {
//        margin-left: 10px;
//        @media @large-desktop-down {
//          margin-left: 0;
//        }
//      }
//    }
//
//    .column-with-boxes {
//      height: 100%;
//      @media (max-width: 575px) {
//        height: unset;
//      }
//      .ant-col {
//        &:nth-child(odd) {
//          padding-right: 5px;
//          @media (max-width: 575px) {
//            padding-right: 0;
//          }
//        }
//
//        &:nth-child(even) {
//          padding-left: 5px;
//          @media (max-width: 575px) {
//            padding-left: 0;
//          }
//        }
//
//        .column-content {
//          margin-right: 10px;
//          @media @large-desktop-down {
//            margin-right: 0;
//          }
//        }
//      }
//    }
//  }
//}
