@import (once) '../../custom/styles/theme.less';
@import (once) 'breakpoints.less';

.cms-module-webshop-boxed {
  @media @large-desktop-down {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
  padding-left: 20px;
  padding-right: 20px;

  .promo-row {
    background-size: cover;
    margin-left: 10px;
    margin-right: 10px;

    h2 {
      color: white !important;
      font-size: 1.2rem !important;
    }

    &.spacing-medium {
      margin-left: 50px;
      margin-right: 50px;
      @media @tablet-down {
        margin-left: unset;
        margin-right: unset;
      }
    }

    &.spacing-large {
      margin-left: 100px;
      margin-right: 100px;
      @media @tablet-down {
        margin-left: unset;
        margin-right: unset;
      }
    }

    background-color: #eee;
    height: 92%;
    min-height: 250px;
    margin-bottom: 50px;
  }

  .products-actions {
    margin-top: 30px;
    margin-bottom: 30px;
    @media @tablet-down {
      padding-left: 0px;
      padding-right: 0px;
    }
    @media @phone-down {
      text-align: center;
    }
    a {
      color: @theme-color-black;
      text-decoration: none;
    }
    .btn-sort,
    .btn-filters {
      border: none;
      height: unset;
      padding: 0;
      margin-right: 20px;
      font-weight: 300;
      svg {
        position: relative;
        top: 4px;
        font-size: 20px;
        fill: none;
        stroke-width: 50px;
        stroke: @theme-color-red;
      }
      &.active {
        font-weight: 600;
        svg {
          fill: @theme-color-red;
        }
      }
    }
    .btn-separator {
      font-weight: 400;
      color: @theme-color-red;
      margin-right: 20px;
    }
    .btn-go-to-cart {
      //font-weight: 600;
      text-transform: uppercase;
      @media @tablet-down {
        display: block;
      }
      @media @phone-down {
        margin-bottom: 30px;
      }
      svg {
        position: relative;
        top: 2px;
        width: 40px;
        line,
        polygon {
          stroke-width: 4px;
          stroke: @theme-color-red;
        }
      }
    }
  }

  h2 {
    font-size: 1rem;
  }

  .products-categories-bar {
    padding: 20px;
    height: 62px;
    background-color: black;
    text-align: center;
    position: absolute;
    top: -62px;
    z-index: 2;
    left: -20px;
    width: calc(100% + 40px);


    .ant-space {
      transition: 0.3s opacity ease-in-out;
      opacity: 1;
    }

    a {
      color: white;
      text-decoration: none;
      &.products-categories-bar-button-active {
        background-color: @theme-color;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 5px;
        transition: 0.3s background-color ease-in-out;
        &:hover {
          background-color: @theme-color-highlight;
          transition: 0.3s background-color ease-in-out;
        }
      }
    }


    &.loading {
      .ant-space {
        transition: 0.3s opacity ease-in-out;
        opacity: 0;
      }
    }

  }
}

.product-boxed-col {
  padding-left: 10px;
  padding-right: 10px;

  &.spacing-medium {
    padding-left: 50px;
    padding-right: 50px;
    @media @tablet-down {
      padding-left: unset;
      padding-right: unset;
    }
  }

  &.spacing-large {
    padding-left: 100px;
    padding-right: 100px;
    @media @tablet-down {
      padding-left: unset;
      padding-right: unset;
    }
  }

  .ant-ribbon {
    &.in-active {
      display: none;
    }
  }

  .product {
    border-radius: 0;
    border: none;
    box-shadow: none;
    margin-bottom: 40px;

    transform: translateZ(0); // bug fix: safari shows border-radius on image hover
    overflow: hidden;
    position: relative;
    height: auto;
    width: 100%;
    display: inline-block;



    .product-image {
      .stock-message {
        background-color: @theme-color-highlight;
        position: absolute;
        top: 0;
        width: 100%;
        height: 35px;
        text-align: center;
        color: @theme-color-black;
        z-index: 2;
        line-height: 35px;

        &.sold-out {
          background-color: rgba(255, 0, 0, 0.8);
          color: white;
        }
      }

      overflow: hidden;
      position: relative;
      img {
        position: relative;
        height: auto;
        object-fit: contain;
        width: 100%;
      }

      .product-quick-buy {
        height: 60px;
        position: absolute;
        transition: 0.2s bottom ease-in-out;
        background-color: rgba(255, 255, 255, 0.8);
        width: 100%;
        bottom: -60px;
        padding: 15px;

        .ant-select {
          .ant-select-selector {
            box-shadow: none;
            &:hover {
              border-color: @theme-color-red;
            }
          }
          svg {
            position: relative;
            top: -4px;
            right: 5px;
            font-size: 20px;
            fill: none;
            stroke-width: 50px;
            stroke: @theme-color-red;
          }
          &:hover,
          &:active,
          &:focus {
            box-shadow: none;
            .ant-select-selector {
              box-shadow: none;
              border-color: @theme-color-red;
            }
          }
          &:active {
            box-shadow: none;
          }
        }

        button {
          margin-top: 0;
          padding-top: 0px;
          padding-bottom: 0px;
          margin-bottom: 0 !important;
          background-color: @theme-color;
          border-color: @theme-color;
          height: 30px;
          width: 100%;
          font-size: 0.8rem;
          color: #444;
          line-height: 30px;

          img {
            margin-left: 5px;
            width: 12px;
            top: -2px;
            position: relative;
          }

          &:hover,
          &:active {
            color: #666 !important;
          }
        }
      }
    }

    &:hover {
      .product-quick-buy {
        transition: 0.2s bottom ease-in-out;
        bottom: 0;
      }
    }
  }
}

.product-details-container {
  padding-left: 20px;
  padding-right: 20px;

  .product-details {
    height: 40px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .product-actions {
    padding-left: 0;
    padding-right: 0;
    &-category-label {
      @media @extra-large-desktop-down {
        display: none;
      }
      @media @large-desktop-down {
        display: inline-block;
      }

      @media @desktop-down {
        display: none;
      }
      @media (max-width: 575px) {
        //display: inline-block;
      }
    }
    button {
      float: none;
      font-size: 0.8rem;
      margin: 0;
      margin-top: 5px;
      height: unset;
      &:last-child {
        float: right;
      }
    }

    .icon-button {
      float: right;
      svg {
        width: auto;
        height: 20px;
      }
      &.active {
        svg {
          path {
            fill: @theme-color-red !important;
          }
        }
      }
    }
  }
}
