
.page-portfolio {
  #section-variant-a {
    background-color: #dbd2c4;

    .ant-col:first-child p:first-child img {
      width: 100%;
    }
  }


  #section-variant-a .ant-col:nth-child(2):before {
    display: none;
  }

  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content h2:after {
    display: none;
  }

  #section-variant-a .ant-col:nth-child(2) .cms-module-column-layout-content p {
    width: 100%;
    max-width: 600px;
  }


  #section-variant-g {
    background-color: white;
    h2,p {
      color: #333 !important;
    }

   &:before {
     background-image: url('../../assets/pattern-b.png');
   }
  }

  #section-variant-h {
    &:before {
      transform: scaleX(-1);
    }
    background-color: #2c2f2e;
    h2,p {
      color: white !important;
    }
  }

  #section-variant-g,#section-variant-h {
    p img {
      width: auto;
      height: 20px;
      margin-bottom: 20px !important;
    }

    .slider-slide {
      img {
        height: unset !important;
        object-fit: contain !important;
      }
    }
  }

  #section-variant-h > .ant-col > .ant-row > .ant-col:nth-child(2) img {
    height: unset !important;
    width: 100% !important;
    object-fit: contain !important;
  }

  #section-variant-h>.ant-col>.ant-row>.ant-col:nth-child(2) {
    padding-left :0;
  }
}