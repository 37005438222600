@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.how-it-works-row {
  overflow: visible !important;
  position: relative;
  &:before {
    background: url('../../../assets/texture-concrete.jpg');
    background-size: cover;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 1;
    content: '';
  }

  .how-it-works-column {
    padding: 80px 0;
  }

  .twig-garlic-image {
    position: absolute;
    top: -90px;
    right: 0;
    z-index: 2;
  }

  .how-it-works-container {
    background-color: white;
    position: relative;
    padding: 40px 60px;
    -webkit-box-shadow: 0px 0px 53px 0px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0px 0px 53px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 53px 0px rgba(0, 0, 0, 0.45);
    z-index: 2;

    @media @desktop-down {
      padding: 20px 20px;

      @media @tablet-down {
        padding-bottom: 60px;
      }
    }

    .ant-steps-item {
      &.steps-3 {
        width: 33%;
      }
      &.steps-4 {
        width: 25%;
      }
      &.steps-5 {
        width: 20%;
      }

      @media @tablet-down {
        width: 100% !important;
      }

      &:last-child {
        .ant-steps-item-tail {
          display: block;
        }
      }
    }

    .ant-steps-item-tail {
      margin-left: 0;
      left: -20px;
      width: 115%;

      &:after {
        background-color: @theme-color !important;

        @media @tablet-down {
          width: 2px !important;
          height: 140% !important;
          top: -150px;
        }
      }

      @media @tablet-down {
        left: 8px !important;
        top: -50px !important;
      }
    }

    .ant-steps-item-icon {
      top: -15px;
      width: 40px !important;
      height: 40px !important;
      left: -50px !important;
      position: relative;
      background-color: white;
      border: 1px solid @theme-color;
      overflow: hidden;
      padding-top: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media @desktop-down {
        left: -65px !important;
      }

      @media @tablet-down {
        left: 0 !important;
      }

      .ant-steps-icon-dot {
        background: white !important;
        border: 3px solid @theme-color !important;
      }
    }

    .ant-steps-item-content {
      text-align: left;
      line-height: 22px;
      margin-left: 20px;
      top: -15px !important;
      position: relative;
      width: 80%;

      &:last-child {
        flex: 1;
      }

      @media @desktop-down {
        margin-left: 0;
      }

      @media @tablet-down {
        width: 80%;
        margin-bottom: 30px;
      }

      @media @phone-down {
        width: 70%;
      }

      .ant-steps-item-title {
        color: @theme-color !important;
        font-size: 24px;
        line-height: 1.7rem;
      }

      .ant-steps-item-description {
        color: black !important;
      }
    }

    h2 {
      color: @theme-color;
      margin-bottom: 40px;
      font-size: 1.5rem;
      line-height: 1.5rem;
      @media @tablet-down {
        margin-bottom: 70px;
      }
    }

    a {
      position: absolute;
      top: 40px;
      right: 60px;

      @media @tablet-down {
        top: unset;
        right: unset;
        bottom: 30px;
        left: 20px;
      }
    }
  }
}
