@import (once) '../../custom/styles/theme';

.register-container {
  padding-top: @theme-section-padding;
  padding-bottom: 40px;
  background-color: #eee;
  min-height: 600px;

  form {
    background-color: white;
    padding: 20px;
    padding-bottom: 0;
    border-radius: 10px;
  }
}
