@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme';

.cms-module {
  &-link-tree {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;

    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    text-align: center;
    h2 {
      text-align: center;
    }

    p {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    &.has-bg-image {
      &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.4;
      }

      h2,
      em,
      p {
        color: white;
      }
    }

    .link-tree-items {
      margin-top: 40px;
      .link-tree-item {
        width: 40%;
        transition: opacity 0.2s ease-in-out;
        display: inline-block;
        padding: 30px;
        color: white;
        border-radius: 0px;
        background-color: @theme-color-black;
        text-decoration: none;
        margin-bottom: 10px;
        font-size: 1rem;
        &:hover {
          text-decoration: none;
          transition: opacity 0.2s ease-in-out;
          opacity: 0.6;
        }
      }
    }
  }
}
