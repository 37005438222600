@import (once) '../../custom/styles/theme.less';

footer.footer-large {
  padding: 0px !important;
  padding-top: 50px !important;
  padding-bottom: 20px !important;
  position: relative;
  background-color: #333;

  &:before {
    content: '';
    background-image: url('../../assets/banner-footer.jpg');
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-size: cover;
  }

  #footer-logo-container {
    text-align: center;
    padding-bottom: 20px;

    img {
      width: 200px;
      margin: 0 auto;
    }
  }

  h4 {
    font-size: 1.2rem;
    color: white;
    font-weight: 700;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      color: white;
      margin-bottom: 10px;
      a {
        color: white;
        text-decoration: unset;
        &:hover {
          text-decoration: unset;
          color: @theme-color-highlight;
        }
      }
    }
  }

  .additional-links {
    transition: 0.3s color ease-in-out;
    span:hover {
      cursor: pointer;
      color: @theme-color-highlight;
      transition: 0.3s color ease-in-out;
    }
  }

  div {
    color: white;
    word-break: break-word;

    span {
    }
  }

  #footer-bottom-container {
    color: white;
    text-align: center;
  }

  #footer-social-media-icons {
    font-size: 2rem;
    a {
      display: inline-block;
      min-width: 40px;
      margin-right: 10px;
      transition: color 0.2s ease-in-out;
      color: white;

      &.custom-svg {
        svg {
          height: 28px;
          path {
            transition: fill 0.2s ease-in-out;
            fill: white;
          }
        }

        &:hover {
          path {
            transition: fill 0.2s ease-in-out;
            fill: @theme-color-highlight;
          }
        }
      }

      &:hover {
        color: @theme-color-highlight;
      }
    }
  }
}
