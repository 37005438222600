@import (once) 'breakpoints.less';
@import (once) '../../custom/styles/theme.less';

#navbar-floating {
  z-index: 3;
  position: fixed;
  left: 27px;
  height: 200px;
  top: 225px;
  background-color: transparent;
  transition: left 0.3s ease-in-out;

  &.header-scroll-margin {
    transition: left 0.3s ease-in-out;
    left: -200px;
  }

  @media @large-desktop-down {
    display: none;
  }

  * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }

  ul#header-main-navigation {
    background: transparent;
    border: none;

    li.ant-menu-item {
      height: 30px;
      line-height: 30px;
      text-transform: uppercase;
      color: @theme-color-black;
      border-right: none;
      letter-spacing: 3px;

      &:after {
        border: none;
      }
      &:hover {
        background-color: transparent;
      }

      &-selected {
        background-color: transparent;
        a {
          color: @theme-color-black;
          font-weight: 500;
          border-bottom: 1px solid @theme-color-black;
        }
      }

      a,
      a:hover {
        color: @theme-color-black !important;
        text-decoration: none;
        font-weight: 500;
      }
    }

    .ant-menu-item-group {
      div.ant-menu-item-group-title {
        padding-left: 24px;
        line-height: 14px;
      }

      ul.ant-menu-item-group-list {
      }
      a {
        padding-left: 20px;
      }
    }

    .ant-menu-submenu,
    .ant-menu-item-group {
      font-weight: 500;

      span {
        font-weight: 500;
      }

      &.active-submenu,
      &.ant-menu-submenu-open {
        span {
          //border-bottom: 1px solid @theme-color-black !important;
        }
      }
      &-arrow {
        display: none;
      }

      &-title {
        letter-spacing: 3px;
        height: 30px;
        line-height: 30px;
        font-weight: 500;
        color: @theme-color-black;
        &:hover {
          color: @theme-color-black;
        }
        &:active {
          background: transparent;
        }
      }

      ul.ant-menu-sub,
      ul.ant-menu-item-group-list {
        background: transparent;

        li.ant-menu-item {
          text-transform: unset;
          margin-bottom: 0;
          margin-top: 0;
          line-height: 22px;
          height: 22px;
          letter-spacing: unset;
          font-size: 0.8rem;
          padding-left: 40px !important;
          &:hover {
            cursor: pointer;

            a {
              &:before {
                content: '—';
                padding-left: 24px;
              }
            }
          }
        }

        a {
          font-weight: 300;
          border: none;
        }

        .ant-menu-item-selected {
          a {
            &:before {
              content: '—';
              padding-left: 24px;
            }
          }
        }

        .anticon-right {
          display: none;
        }
      }
    }
  }
}
