@import (once) '../../custom/styles/theme.less';
@import (once) 'breakpoints';

footer.footer-basic {
  padding: 0px !important;
  padding-top: 50px !important;
  padding-bottom: 20px !important;
  position: relative;
  background-color: unset;

  #newsletter-subscription {
    h3 {
      font-size: 1rem;
      line-height: 1rem;
      margin-top: 20px;
    }

    ::placeholder {
      line-height: 1.5rem;
    }

    .ant-input-group-addon {
      padding: 0;

      button {
        padding: 5px;
        padding-top: 7px;
        height: auto;
        line-height: 1rem;
      }
    }
  }

  @media @large-desktop-down {
    padding: 20px !important;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 10px;
      a {
        text-decoration: unset;
        &:hover {
          text-decoration: unset;
        }
      }
    }
  }

  #footer-left-col {
    padding-right: 20px;
    h3 {
      margin-bottom: 10px;
    }
  }

  .additional-links {
    transition: 0.3s color ease-in-out;
    span:hover {
      cursor: pointer;
      color: @theme-color-highlight !important;
      transition: 0.3s color ease-in-out;
    }
  }

  div {
    color: black;
  }

  #footer-bottom-container {
    padding-top: 10px;
    border-top: 1px solid #eee;
    color: black;
    text-align: left;
    &-right {
      text-align: right;
    }
  }

  #footer-social-media-icons {
    a {
      margin-right: 10px;
      transition: color 0.2s ease-in-out;
      &:hover {
        color: @theme-color-highlight !important;
      }
    }
    svg {
      font-size: 2rem;
      color: white;
      height: 30px;
      width: auto;

      path {
        fill: black;
        stroke: white;
        stroke-width: 3px;
      }
    }
  }

  .language-selection {
    margin-top: 30px;
    font-size: 0.8rem;
    &-language {
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
      img {
        width: 20px;
      }
    }
  }
}
