@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';

#header-navbar {
  .ant-dropdown {
    z-index: 3000;
  }

  #header-main-hamburger span {
    background: @theme-color-gold !important;
  }

  #header-main-logo {
    img {
      object-fit: contain;
    }
  }

  //#header-main-logo {
  //  img {
  //    top: -50px;
  //    position: absolute;
  //    transition: 0.3s all ease-in-out;
  //    height: 120px;
  //    max-height: 999px;
  //    max-width: 500px;
  //    width: auto;
  //
  //    @media @tablet-down {
  //      height: 100px;
  //      top: -20px;
  //    }
  //  }
  //}
  //
  //&.header-scrolled {
  //
  //  #header-main-logo {
  //    img {
  //      transition: 0.3s all ease-in-out;
  //      top: -65px;
  //      //height: 120px;
  //      //max-height: 999px;
  //      max-width: 200px;
  //      width: auto;
  //    }
  //  }
  //}
}