@import (once) 'breakpoints.less';

#cookie-banner {
  z-index: 1001;
  background-color: lightpink;
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;

  -webkit-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.23);

  h4 {
    color: black;
  }

  #cookie-banner-content {
    color: black;
    a {
      color: black;
    }
    padding: 20px;
    padding-left: 40px;
    @media @tablet-down {
      padding-bottom: 0;
      padding-left: 20px;
    }
  }

  #cookie-banner-buttons {
    padding: 20px;
    @media @tablet-down {
      padding-top: 0;
    }

    button {
      width: auto;
      margin-top: 10px;
      &#cookie-banner-accept {
        margin-right: 10px;
        background-color: black;
        color: white;
        border-color: black;
      }
    }
  }
}
