@import (once) '../../custom/styles/theme.less';
@import (once) 'breakpoints';

.contact-basic-container {
  padding-top: @theme-section-padding;
  padding-bottom: @theme-section-padding;

  @media @tablet-down {
    padding-top: @theme-section-padding-mobile;
    padding-bottom: @theme-section-padding-mobile;
  }

  .contact-address {
    padding: 20px;
    @media @tablet-down {
      padding-left: 0;
      padding-right: 0;
    }
  }

  button {
    float: right;
  }
}
