@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme';

.cms-module {
  &-lookbooks {
    .lookbook-menu {
      border-bottom: 1px solid @theme-color;
      padding: 20px;
      .lookbook-year {
        display: inline-block;
        margin-left: 20px;
        margin-right: 20px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;

        &:hover {
          color: @theme-color-highlight;
          cursor: pointer;
        }

        &-active {
          background-color: @theme-color;
          border-radius: 5px;
          transition: 0.3s background-color ease-in-out;
          &:hover {
            background-color: @theme-color-highlight;
            transition: 0.3s background-color ease-in-out;
            color: white;
          }
        }
      }
    }

    .lookbook-single-row {
      background-color: #333;
      height: 600px;
      position: relative;
      padding: 50px;
      @media @desktop-down {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      img {
        display: block;
        z-index: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 0.4;
      }

      &-title {
        padding: 50px;
        position: relative;
        z-index: 10;
        h2 {
          font-size: 2rem;
          margin-bottom: 20px;
          color: #d0b196 !important;
        }
        p {
          color: white;
        }
      }
    }
  }
}
