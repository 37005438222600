@import (once) 'breakpoints.less';
@import (once) '../../custom/styles/theme.less';

#header-menu {
  li {
    a {
      text-decoration: none;
    }
  }

  .ant-anchor-ink {
    display: none;
  }

  .ant-anchor-link {
    padding: 5px;
    font-size: 1rem;
    padding-left: 0 !important;

    &-active > .ant-anchor-link-title {
      color: black;
    }
  }

  .ant-anchor-wrapper {
    margin-left: 0;
    padding-left: 0;
    background-color: unset;
  }
}

#product-search-box {
  box-shadow: 0px 0px 25px -12px rgba(0, 0, 0, 0.52);

  background-color: white;
  padding: 20px;
  max-width: 400px;

  @media @tablet-down {
    width: 100vw;
  }

  .ant-input {
    width: 350px;
    @media @tablet-down {
      width: 100%;
    }
  }

  color: black;
  &-list {
    max-height: 300px;
    overflow-y: scroll;

    .product-search-box-product {
      margin-bottom: 2px;
      img {
        width: 50px;
        height: auto;
      }
      .product-search-box-name {
        display: inline-block;
        padding-left: 10px;
        width: calc(100% - 50px);
        a {
          color: @theme-color-black !important;
        }
      }

      .product-search-box-price {
        display: inline-block;
        float: right;
      }
    }
  }
}

#header-navbar {
  width: 100%;
  z-index: 2000;
  padding-left: 0;
  padding-right: 0;
  position: fixed;
  transition: background-color 0.2s ease-in-out;

  background-color: white !important;
  background: white !important;
  -webkit-box-shadow: 0px -6px 42px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -6px 42px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -6px 42px -12px rgba(0, 0, 0, 0.75);

  #header-voucher {
    background-color: #242a30;
    height: 35px;
    line-height: 25px;
    color: white;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.75rem;
    .align-right {
      text-align: right;
    }
    a {
      color: white;
      text-decoration: unset;
      &:hover {
        color: white;
      }
    }

    &.has-voucher {
      background-color: blueviolet;
      .voucher-text {
        text-align: center;
        font-size: 1.15rem;
        @media @tablet-down {
          font-size: 0.8rem;
        }
      }
    }
  }

  #header-main {
    z-index: 2004;
    position: relative;

    &-logo {
      padding-left: 20px;
      display: inline-block;
      color: black;
      padding-right: 20px;
      position: relative;

      img {
        height: 40px;
        width: auto;
        max-height: 70px;
        max-width: 100%;

        @media @large-desktop-down {
          width: 100%;
          height: auto;
        }
      }

      //@media @phone-down {
      //  padding-left: 8px;
      //  padding-right: 8px;
      //}
    }

    &-navigation {
      background-color: transparent;
      border-bottom: none !important;
      line-height: 30px;

      .ant-menu-item,
      .ant-menu-submenu {
        a {
          text-decoration: none;
        }

        &:hover,
        &-open,
        &-selected {
          .ant-menu-title-content a,
          .ant-menu-title-content {
            color: @theme-color-highlight !important;
          }

          &:after {
            border-color: @theme-color-highlight !important;
          }
        }
      }
    }

    &-auth-menu {
      padding-right: 20px;
      font-size: 15px;
      float: right;
      display: inline-block;
      &:hover {
        cursor: pointer;
      }

      #currency-loading {
        font-size: 10px;
        margin-right: 10px;
        top: -3px;
        position: relative;
        @media @tablet-down {
          margin-right: 0;
        }
      }

      img {
        width: 18px;
        position: relative;
        top: -3px;
      }

      @media @phone-down {
        padding-right: 5px;
      }

      a {
        height: 64px;
        display: inline-block;
      }
    }

    #login-menu {
      margin-left: 10px;
    }

    #currency-icon {
      margin-right: 10px;

    }

    #wishlist-icon {
      margin-right: 10px;
      margin-left: 10px;
    }

    #search-product-icon {
      margin-right: 10px;
      margin-left: 10px;
    }

    #shopping-cart {
      margin-right: 10px;
      margin-left: 10px;

      //@media @tablet-down {
      //  margin-right: 0;
      //}

      //float: right;
      position: relative;
      //@media @tablet-down {
      //  top: 4px;
      //}

      .header-cart-badge {
        //font-size: 1.2rem;
        //margin-left: 20px;
        //@media @tablet-down {
        //  margin-right: 20px;
        //}

        p {
          line-height: 1.2rem;
          font-size: 0.8rem;
        }
      }
    }

    #header-menu-mobile {
      float: right;
      display: inline-block;
      position: relative;
      padding: 15px;
      padding-top: 20px;

      #header-main-hamburger {
        //float: right;
        width: 30px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        height: 20px;

        span {
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          background: black;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.25s ease-in-out;
          -moz-transition: 0.25s ease-in-out;
          -o-transition: 0.25s ease-in-out;
          transition: 0.25s ease-in-out;

          &:nth-child(1) {
            top: 0px;
          }

          &:nth-child(2) {
            top: 9px;
          }

          &:nth-child(3) {
            top: 9px;
          }

          &:nth-child(4) {
            top: 18px;
          }
        }

        &.open {
          span {
            &:nth-child(1) {
              top: 9px;
              width: 0%;
              left: 50%;
            }

            &:nth-child(2) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            &:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }

            &:nth-child(4) {
              top: 9px;
              width: 0%;
              left: 50%;
            }
          }
        }
      }
    }
  }

  #header-categories {
    background-color: slategray;
    z-index: 1;
    position: relative;
    .ant-col {
      text-align: center;
      nav {
        display: inline-block;
        .ant-menu {
          background: unset;
          line-height: 35px;
          border: none;
          .ant-menu-item-selected {
            border-bottom-color: white !important;
          }
          li {
            margin-right: 5px;
            a {
              color: white !important;
            }
          }
          .ant-menu-item:hover {
            border-bottom-color: white !important;
          }
        }
      }
    }
  }

  .dropdown-label {
    @media @desktop-down {
      display: none;
    }
  }
}

.menu-total {
  text-align: right;
}

.menu-amount {
  text-align: right;
}

.menu-price {
  text-align: right;
}

.menu-total-price {
  text-align: right;
}

.menu-no-items {
  padding: 20px;
}

.ant-menu-submenu {
  z-index: 2001;
  a.no-underline {
    text-decoration: none !important;
  }
  a:hover {
    color: rgba(0, 0, 0, 0.65);
  }
  li:hover {
    background-color: #eeeeee;
  }
}

.ant-dropdown {
  z-index: 2001;
  max-width: 100%;
  position: absolute;
  &-menu {
    width: 400px;
    max-width: 100%;
    background: white !important;

    &-submenu-title {
      color: @theme-color-black;
    }

    &-item {
      padding: 13px;
      min-width: 150px;
      max-width: 100%;
      button {
        max-width: 100%;
        color: @theme-color-black;
      }
      a {
        color: @theme-color-black;
        text-decoration: none;
      }
    }
  }
}


