@import (once) 'breakpoints.less';
@import (once) '../../custom/styles/theme.less';

body {
  color: @theme-color-black;
}

h1 {
  font-size: 2rem;
  color: @theme-color;
  margin-bottom: 2rem;
}

h2 {
  font-size: @theme-h2-font-size;
  line-height: @theme-h2-line-height;
  color: @theme-color;
  margin-bottom: 2rem;
}

h3 {
  font-size: @theme-h3-font-size;
  line-height: @theme-h3-line-height;
  margin-bottom: 2rem;
}

p {
  font-size: @theme-p-font-size;
  line-height: @theme-p-line-height;
}

.disable-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

main {
  padding-top: 64px;
  &.has-categories-bar {
    padding-top: 128px;
  }
}

.content-header {
  text-align: center;
  height: 350px;
  //padding: 0px 20px 20px;
  line-height: 3.2rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .image-list-container {
    @media @tablet-down {
      top: calc(50% - 50px);
    }
  }

  &.full-page-carousel {
    .ant-carousel {
      width: 100%;
      height: 100%;
    }
  }

  img.single-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .queue-anim-container {
    position: relative;
    z-index: 2;
    img {
      max-width: 100%;
    }
  }

  &.header-small {
    height: 350px;
  }

  &.header-medium,
  &.header-medium-with-fluid {
    height: 600px;
  }

  &.header-large,
  &.header-large-with-fluid {
    height: calc(100vh - 64px);
    @media @tablet-down {
      height: 75vh;
    }
  }

  &.header-extra-large {
    height: 120vh;
  }

  .fluid-decoration-element {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    z-index: 1;
  }

  &.with-overlay {
    &:before {
      display: block;
      z-index: 1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: black;
      opacity: 0.4;
    }
  }

  &-header {
    background-image: url('../../assets/header-main.jpg');
  }

  h1 {
    color: white;
    z-index: 2;
    position: relative;
    font-size: 45px;
    font-weight: 300;
    margin-bottom: 0;

    p {
      margin-bottom: 0;
      font-size: unset;
    }

    div {
      line-height: 2.2rem;
      font-size: 60%;
    }

    strong {
      font-weight: 700;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 300;
    color: white;
    strong {
      font-weight: 700;
    }
  }

  em {
    color: white;
    z-index: 2;
    position: relative;
  }

  .breadcrumbs {
    position: absolute;
    bottom: 40px;
    left: 100px;
    z-index: 2;
    border-radius: 5px;
    background-color: black;
    padding-left: 5px;
    padding-right: 5px;
    span {
      padding-left: 2px;
      padding-right: 2px;
      padding-top: 5px;
      padding-bottom: 5px;
      display: inline-block;
      color: white !important;
      word-break: keep-all;
      @media @tablet-down {
        font-size: 0.8rem;
      }
    }

    a {
      color: white !important;
      @media @tablet-down {
        font-size: 0.8rem;
      }
    }

    @media @tablet-down {
      left: 20px;
    }
    @media @phone-down {
      bottom: 10px;
    }
  }
}

.content-body {
  position: relative;
  h2 {
    margin-bottom: 40px;
  }

  h3 {
    margin-bottom: 30px;
  }
}

.ant-layout {
  background-color: transparent !important;
}

.ql-align-center {
  text-align: center;
}

blockquote {
  border-left: 2px solid #ccc;
  padding-left: 20px;
  color: @theme-color;
  width: 50%;
}

.form-section {
  margin-bottom: 20px;
  h2 {
    margin-bottom: 10px;
  }
}

.form-action-highlight {
  padding: 10px;
  background-color: @theme-color-light;
  .ant-row {
    margin-bottom: 0;
  }
  margin-bottom: 10px;
}

.icon-button {
  display: inline-block;
  font-size: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  svg {
    transition: color 0.3s ease-in-out;
  }

  &.active {
    color: salmon;
  }

  &:hover {
    cursor: pointer;
    svg {
      color: salmon;
      transition: color 0.3s ease-in;
    }
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: @theme-color;
  box-shadow: 0 0 0 2px @theme-color-shadow;
}

.ant-input {
  &:focus {
    border-color: @theme-color;
    box-shadow: 0 0 0 2px @theme-color-shadow;
  }
}

.ant-layout-header {
  color: @theme-color-black;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: @theme-color;
}

.ant-input:hover {
  border-color: @theme-color;
}

.ant-select:not(.ant-select-disabled) {
  &:hover {
    .ant-select-selector {
      border-color: @theme-color;
    }
  }
  &:active {
    .ant-select-selector {
      border-color: @theme-color;
    }
  }
}

a {
  color: @theme-color;
  text-decoration: underline;
  &:hover {
    color: @theme-color-highlight;
    text-decoration: underline;
  }
}

.ant-checkbox-checked {
  &:after {
    border-color: @theme-color;
  }
  .ant-checkbox-inner {
    background-color: @theme-color;
    border-color: @theme-color;
  }
}

.ant-btn-primary {
  background-color: @theme-color;
  border-color: @theme-color;
  &:hover {
    color: white !important;
    background-color: @theme-color-highlight;
    border-color: @theme-color-highlight;
  }
  &:focus {
    background-color: @theme-color !important;
    border-color: @theme-color !important;
    color: white !important;
  }
}

.ant-btn:hover,
.ant-btn:focus {
  color: @theme-color-highlight;
  border-color: @theme-color-highlight;
}

.ant-btn-ghost {
  color: @theme-color-black;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: @theme-color;
}

.ant-pagination-item-active a {
  color: @theme-color;
  &:hover {
    color: @theme-color-highlight;
  }
}

.ant-pagination-item-active {
  border-color: @theme-color;
  &:hover {
    border-color: @theme-color-highlight;
  }
}

.swal2-styled.swal2-confirm {
  background-color: @theme-color !important;
  transition: 0.2s all ease-in-out;
  &:hover {
    transition: 0.2s all ease-in-out;
    background-color: @theme-color-highlight !important;
  }
  &:focus {
    box-shadow: none;
  }
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none;
}

.swal2-styled.swal2-cancel {
  background-color: white;
  color: @theme-color;
  border: 1px solid @theme-color;
  transition: 0.2s all ease-in-out;
  &:hover {
    transition: 0.2s all ease-in-out;
    background-color: @theme-color-highlight;
    border-color: @theme-color-highlight;
    color: white;
  }
  &:focus {
    box-shadow: none;
  }
}

.ant-tabs-tab {
  &.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: @theme-color;
    }
  }
  &:hover {
    color: @theme-color-highlight !important;
  }
}

.ant-tabs-ink-bar {
  background-color: @theme-color;
}

.decoration {
  position: absolute;
  max-width: 200px;
  height: auto;

  &-top-left {
    top: 30px;
    left: -20px;
  }
  &-bottom-right {
    bottom: 30px;
    right: -20px;
  }
}

.desktop-label {
  @media @tablet-down {
    display: none;
  }
}

.ant-drawer {
  z-index: 2000;

  .ant-drawer-title {
    color: @theme-color-black;
  }

  .ant-drawer-body {
    .ant-checkbox-wrapper {
      margin-bottom: 5px;
    }
  }

  .cart-item {
    margin-bottom: 20px;
    //&:last-child {
    //  margin-bottom: 0;
    //}
  }

  .cart-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .cart-item-details-name {
    margin-left: 15px;
  }

  .ant-ribbon {
    &.in-active {
      display: none;
    }
    line-height: 19px;
    .ant-ribbon-text {
      font-size: 10px;
    }
  }



  .cart-item-detail {
    margin-left: 15px;
    font-size: 1rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .cart-item-price {
    text-align: right;
    font-size: 1rem;
  }

  #shopping-list-title {
    display: none;
  }

  #shopping-list-totals {
    p {
      font-size: 1rem;
    }
    .ant-col:last-child {
      text-align: right;
    }
  }
}

.product-image-slider {
  .slick-prev {
    left: 5px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.4) !important;
    height: 100%;
    z-index: 2;
    width: 35px;
    top: 10px;
    &:hover {
      transition: background-color 0.3s ease-in-out;
      background-color: rgba(255, 255, 255, 0.8) !important;
    }
    &:before {
      color: black;
      font-size: 52px;
      content: '\2039' !important;
    }
  }

  .slick-next {
    right: 5px;
    transition: background-color 0.3s ease-in-out;
    background-color: rgba(255, 255, 255, 0.4) !important;
    height: 100%;
    z-index: 3;
    width: 35px;
    top: 10px;
    &:hover {
      transition: background-color 0.3s ease-in-out;
      background-color: rgba(255, 255, 255, 0.8) !important;
    }
    &:before {
      color: black;
      font-size: 52px;
      content: '\203A' !important;
    }
  }
}

.ant-tooltip {
  z-index: 2000;
}

.image-list-container {
  position: absolute;
  right: 40px;
  top: calc(50vh - 50px);

  .image-list {
    list-style: none;
    line-height: 20px;

    li {
      user-select: none;
      border-bottom: 1px solid #ccc;
      padding-top: 5px;
      padding-bottom: 5px;
      &:hover {
        cursor: pointer;
        color: #666;
      }
      &.active {
        font-weight: 500;
      }
    }
  }

  svg {
    right: 2px;
    width: 10px;
    position: absolute;
    bottom: -100px;
    line,
    polygon {
      fill: none;
    }
  }
}


.ant-result {
  .ant-result-icon {
    path {
      //fill: @theme-color-light;
    }
  }

  .ant-result-title {
    line-height: 1.7rem;
    font-size: 1.3rem;
  }
}


