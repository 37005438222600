@import (once) '../breakpoints.less';
@import (once) '../../../custom/styles/theme.less';

.cms-module {
  &-upcoming-events {
    padding-top: @theme-section-padding;
    padding-bottom: @theme-section-padding;

    @media @tablet-down {
      padding-top: @theme-section-padding-mobile;
      padding-bottom: @theme-section-padding-mobile;
    }

    #no-events-text {
      text-align: center;
      font-weight: bold;
      height: 150px;
      padding-top: 70px;
      font-size: 1.2rem;
    }

    @media @tablet-down {
      padding-left: 20px;
      padding-right: 20px;
    }

    .upcoming-event {
      background-color: white;
      position: relative;
      overflow: hidden;

      &-description {
        padding: 40px;
        h3 {
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 1.4rem;
          margin-bottom: 10px;
        }
        p {
          font-size: 1rem;
          margin-bottom: 30px;
        }
      }

      &-image-container {
        overflow: hidden;
        background-color: #eee;
        img {
          transition: transform 0.3s ease-out;
          height: 400px;
          width: 100%;
          object-fit: cover;
        }
      }
      &:hover {
        img.event-image-zoom {
          transform: scale(1.1);
          transition: transform 0.3s ease-in;
        }
      }
    }
  }
}
