@import (once) '../../custom/styles/theme.less';
@import (once) 'breakpoints';

footer.footer-minimal {
  background-color: white;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;

  @media @large-desktop-down {
    padding-left: 20px;
    padding-right: 20px;
  }

  img {
    width: 33px;
    height: auto;
    float: right;
  }

  #subscribe-row {
    @media @large-desktop-down {
      padding-top: 1px;
    }


    #subscribe-button {
      margin-left: 80px;
      color: @theme-color-black;
      text-decoration: none;
      position: relative;
      @media @large-desktop-down {
        margin-left: 20px;
      }
      @media @tablet-down {
        top: -5px;
        float: right;
        background-color: @theme-color;
        padding: 5px;
        border-radius: 3px;
        color: white;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  #footer-social-media-icons {
    a {
      width: 20px;
      height: 20px;
      color: @theme-color-black;
      margin-left: 30px;
      @media @large-desktop-down {
        margin-left: 10px;
      }
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: black;
      }
    }
  }

  #footer-links {
    text-align: right;

    @media @large-desktop-down {
      text-align: center;
    }

    //@media @desktop-down {
    //  text-align: right;
    //}

    @media @tablet-down {
      text-align: left;
    }

    #links {
      //@media (max-width: 574px) {
      //  margin-top: 20px;
      //}
      @media @large-desktop-down {
        a.footer-link {
          padding-left: 5px;
          padding-right: 5px;
          display: inline-block;
          //width: 33%;
          margin-bottom: 10px;
          margin-right: 0;
        }
      }

      @media @tablet-down {
        margin-top: 20px;
        a.footer-link {
          margin-left: 0;
          margin-right: 10px;
          padding: 10px;
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
    }

    #copyright {
      text-align: right;
      img {
        position: relative;
        top: -5px;
      }

      @media @phone-down {
        text-align: left;
      }

      @media @desktop-down {
        margin-top: 20px;
      }

      #copy-right-text {
        margin-right: 50px;
        font-size: 0.7rem;
        color: #aaa;
        @media @tablet-down {
          margin-right: 10px;
        }
        @media @large-desktop-down {
          margin-right: 10px;
        }
      }
    }

    .footer-link {
      color: @theme-color-black;
      text-decoration: none;
      margin-right: 15px;
    }
  }

  #newsletter-subscription {
    padding: 50px !important;
    padding-bottom: 10px !important;
    //h3 {
    //  font-size: unset;
    //  line-height: unset;
    //}
    @media @tablet-down {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}
