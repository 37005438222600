@import (once) 'theme';
@import (once) '../../core-module/styles/breakpoints.less';

.page-home {

  .react-parallax {
    &.section-promo {
      .react-parallax-bgimage {
        top: -150px;
        left: calc(50% + 150px) !important;
      }
    }
  }

  #section-variant-b {
    @media @tablet-down {
      background-color: transparent;
    }
  }

  #section-variant-b .ant-col:nth-child(2) p:first-child, #section-variant-e .ant-col:nth-child(2) p:first-child {
    width: 100%;
  }

}
